import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UsuarioSecundarioRequest} from '../../../model/usuarioSecundario/usuarioSecundarioRequest';
import {UsuarioSecundarioGet} from '../../../model/usuarioSecundario/usuarioSecundarioGet';
import {environment} from "../../../../environments/environment"; //import del ambiente de trabajo

@Injectable({
    providedIn: 'root'
})

export class UsuarioSecundarioService {

    private apiURL = environment.api_url; //Optener la url del ambiente de trabajo.

    constructor(private httpClient: HttpClient) {
    }

    public queryUsuarioSecundario(numeroPagina: number, busquedaGeneral?: string) {
        var params = new HttpParams().set('numPagina', '' + numeroPagina);
        if (busquedaGeneral) {
            params = params.set('busquedaGeneral', busquedaGeneral);
        }
        return this.httpClient.get<UsuarioSecundarioGet[]>(`${this.apiURL}/usuario-secundario`, {params});
    }

    public getUsuarioSecundario(id: string) {
        var query;
        return this.httpClient.get<UsuarioSecundarioGet>(`${this.apiURL}/usuario-secundario/${id}`);
    }

    public updateUsuarioSecundario(id: string, usuario: UsuarioSecundarioGet) {
        return this.httpClient.put(`${this.apiURL}/usuario-secundario/${id}`, usuario);
    }

    public disableUsuarioSecundario(id: string, usuario: UsuarioSecundarioGet) {
        return this.httpClient.put(`${this.apiURL}/usuario-secundario/deshabilitar/${id}`, usuario);
    }

    public enableUsuarioSecundario(id: string, usuario: UsuarioSecundarioGet) {
        return this.httpClient.put(`${this.apiURL}/usuario-secundario/habilitar/${id}`, usuario);
    }

    public postUsuarioSecundario(usuario: UsuarioSecundarioRequest) {
        return this.httpClient.post(`${this.apiURL}/usuario-secundario`, usuario)
    }

    public resetPasswordUsuarioSecundario(usuario: UsuarioSecundarioGet) {
        return this.httpClient.post(`${this.apiURL}/usuario-secundario/reinicio-clave`, usuario);
    }

}
