import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from '../../components/auth/login.component';
import {ForgotPasswordComponent} from '../../components/auth/forgot-password.component';
import {ForgotPasswordStep2Component} from '../../components/auth/forgot-password-step-2.component';
import {LogoutComponent} from '../../components/auth/logout.component';
import {NewPasswordComponent} from '../../components/auth/new-password.component';
import {ResendComponent} from '../../components/auth/resend.component';
import {AuthRoutingModule} from './auth-routing.module';
import {AboutComponent} from '../../components/auth/about.component';
import {RegisterComponent} from '../../components/auth/register.component';
import {ConfirmRegistrationComponent} from '../../components/auth/confirm-registration.component';
import {FormsModule} from '@angular/forms';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TextMaskModule} from 'angular2-text-mask';
import {ToasterModule} from 'angular2-toaster';
import {ModalModule} from 'ngx-bootstrap';
import {MFAComponent} from '../../components/auth/mfa.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TabsModule,
    AuthRoutingModule,
    ModalModule,
    TextMaskModule,
    ToasterModule
  ],
  declarations: [
    MFAComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordStep2Component,
    LogoutComponent,
    NewPasswordComponent,
    ResendComponent,
    AboutComponent,
    RegisterComponent,
    ConfirmRegistrationComponent,
  ]
})
export class AuthModule {
}
