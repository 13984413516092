import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserLoginService} from '../../../service/user-login.service';
import {ProfileService} from '../../../service/index';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  providers: [ProfileService]
})

export class AppHeaderComponent implements OnInit {

  public profile: any;

  constructor(public router: Router, public profileService: ProfileService, public userService: UserLoginService) {

  }

  ngOnInit() {
    let sub = this.profileService.getProfile().subscribe(
      (data) => {
        this.profile = data;
      },
      (error) => {
        console.log("ERROR DE PERFIL" + error);
      },
      () => {
        sub.unsubscribe();
      }
    );
  }


  /**
   * Metodo que genera un hash a partir de un string
   */
  hashCode(str) {
    var hash = 0;
    if (str) {
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
    }
    return hash;
  }

  /**
   * genera un color de rbg valido a partir de un int, el int es generado por una funcion de hash
   */
  intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }

  /**
   * Cierra la sesión del usuario
   */
  logout() {
    this.userService.isAuthenticated().subscribe((response) => {
      if (response.valid) {
        this.userService.logout();
      }
      this.router.navigate(["/checkauth/"]);
    }, (error) => {
      this.router.navigate(["/checkauth/"]);
    });
  }

  gotoProfile(path: string) {
    let route = this.router.routerState.snapshot.url.split("/");
    if (route.find(value => {
      return value == "admin"
    })) {
      this.router.navigate(["/evex/admin/console/perfil/" + path]);
    } else {
      this.router.navigate(["/evex/user/console/perfil/" + path]);

    }
  }
}
