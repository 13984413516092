import {Injectable} from '@angular/core';
import {IncomingReceipt} from '../model/persistence/incomingReceipt'


@Injectable()
export class PersistenceService {

  private currentIncomingReceipt: IncomingReceipt;
  private currentOutcomingReceipt: IncomingReceipt;

  constructor() {
    this.currentIncomingReceipt = new IncomingReceipt();
    this.currentOutcomingReceipt = new IncomingReceipt();
  }

//===================================================================================    
  //Retornar el objeto de persistencia
  getCurrentIncomingReceipt(): IncomingReceipt {
    return this.currentIncomingReceipt;
  }

  updateIncomingItemAbierto(identificator: string) {
    this.currentIncomingReceipt.receiptList.forEach(element => {
      if (element.idComprobante == identificator) {
        element['fueAbierto'] = true;
      }
    });
  }

  cleanCurrentIncomingReceipt() {
    this.currentIncomingReceipt.receiptList = [];
  }

//===================================================================================


//===================================================================================
  //Retornar el objeto de persistencia
  getCurrentOutcomingReceipt(): IncomingReceipt {
    return this.currentOutcomingReceipt; //Retornar una copia del objeto
  }

  //Agregar elementos a la lista de la persistencia
  appendCurrentOutcomingReceipt(newItems: any[], lastEvaluatedKey: boolean, page: number): IncomingReceipt {
    Array.prototype.push.apply(this.currentOutcomingReceipt.receiptList, newItems);//concatenar arrays
    this.currentOutcomingReceipt.lastEvaluatedKey = lastEvaluatedKey;
    this.currentOutcomingReceipt.page = page;

    return this.getCurrentOutcomingReceipt();
  }

  //reiniciar el listado y agregar nuevos elemento
  newAppendCurrentOutcomingReceipt(newItems: any[], lastEvaluatedKey: boolean, searchQuery: any): IncomingReceipt {
    this.currentOutcomingReceipt.page = 1;
    this.currentOutcomingReceipt = new IncomingReceipt();
    this.currentOutcomingReceipt.lastEvaluatedKey = lastEvaluatedKey;
    this.currentOutcomingReceipt.searchQuery = searchQuery;
    this.currentOutcomingReceipt.lastUpdate = new Date();
    Array.prototype.push.apply(this.currentOutcomingReceipt.receiptList, newItems);//concatenar arrays
    return this.getCurrentOutcomingReceipt();
  }

  updateOutcomingItemAbierto(identificator: string) {
    this.currentOutcomingReceipt.receiptList.forEach(element => {
      if (element.idComprobante == identificator) {
        element['fueAbierto'] = true;
      }
    });
  }

  cleanCurrentOutcomingReceipt() {
    this.currentOutcomingReceipt.receiptList = [];
  }

//===================================================================================


}


