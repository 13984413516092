import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginService, UserRegistrationService} from '../../service';
import {CognitoCallback, NewPasswordUser, UserProfile} from '../../model';
import {ToasterService} from 'angular2-toaster';
import {ConfigProvider} from "../../service/config-provider.service";

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
  selector: 'new-password',
  templateUrl: './new-password.component.html',
  styles: [`.spinner {
    margin: auto;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }`],
  providers: [UserRegistrationService]
})
export class NewPasswordComponent implements CognitoCallback, OnInit {
  registrationUser: NewPasswordUser;
  errorMessage: string;
  loading = false;
  private accName: string;

  constructor(public userRegistrationService: UserRegistrationService,
              public userService: UserLoginService,
              private router: Router,
              private profile: UserProfile,
              private route: ActivatedRoute,
              private configProvider: ConfigProvider,
              private messageService: ToasterService) {
    this.router = router;
    this.onInit();
  }

  onInit() {
    this.registrationUser = new NewPasswordUser();
    this.accName = this.route.snapshot.paramMap.get('acc');
    this.registrationUser.username = this.route.snapshot.paramMap.get('email');
    this.errorMessage = null;
    console.log(this.profile);
  }

  ngOnInit() {
    this.errorMessage = null;
    // console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
    this.userService.isAuthenticated().subscribe((resp) => {
      if (resp.valid) {
        this.userService.cognitoUtil.getIdToken().subscribe((tkn) => {
          this.registrationUser.username = this.parseJwt(tkn)["cognito:username"];
        });
      }
    });
  }

  onRegister() {
    console.log(this.registrationUser);
    this.errorMessage = null;

    if (this.configProvider.getConfig().indTipo == 'user') {
      this.registrationUser.username = this.accName + "&evex&" + this.registrationUser.username;
    }
    this.userRegistrationService.newPassword(this.registrationUser, this);
  }

  cognitoCallback(message: any, result: any) {
    console.log(message, result);
    if (message != null) { //error
      if (message.message == "Incorrect username or password.") {
        this.messageService.pop("error", "Error", "Usuario o contraseña incorrectos");
      } else {
        this.messageService.pop("error", "Error", "Error al cambiar la contraseña");
      }

      console.log('result: ' + this.errorMessage);
    } else { //success
      this.messageService.pop("success", "Actualizado", "La contraseña se ha cambiado satisfactoriamente");
      //move to the next step
      console.log('redirecting');
      this.router.navigate(["/checkauth/"]);
    }
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

  }

  translate(message: string) {
    switch (message) {
      case"": {

      }
    }
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };
}
