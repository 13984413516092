import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {ChallengeParameters} from "../../service/cognito.service";
import {ErrorHandlerService} from "../../service/error-handler.service";
import {ToasterService} from "angular2-toaster";
import {ConfigProvider} from "../../service/config-provider.service";

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public cuenta: string;
  public email: string; //email o nombre de usuario
  public password: string;
  public errorMessage: string;
  public error: boolean = false;
  public loading: boolean = false;
  public mfaStep = false;
  public mfaData = {
    destination: '',
    callback: null
  };
  public isAuthenticated: boolean = true;
  //almacena el modo en el que se encuentra la aplicacion, si modo administrador o modo usuario regular
  public accType = "";

  constructor(public router: Router,
              public userService: UserLoginService,
              private errorHandlerService: ErrorHandlerService,
              private msgService: ToasterService,
              private configProvider: ConfigProvider,
              private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.accType = this.configProvider.getConfig().indTipo;
    console.log(this.accType);
    this.errorMessage = null;
    this.isLoggedIn();

  }

  openFAQsLink() {
    window.open('https://app.evex.cr/0_PDF_FAQs/FAQs_EVEx.pdf');
  }

  gotoNewPasword() {
    console.log("redirecting to set new password");
    this.router.navigate(['../newPassword', this.email],{relativeTo: this.activatedRoute});
  }


  onLogin() {
    this.loading = true;
    if ((this.accType == 'admin' && (!this.email || !this.password)) || ((this.accType == 'user') && (!this.email || !this.password || !this.accType))) {
      this.errorMessage = "Todos los campos son requeridos";
      this.loading = false;
      return;
    }
    this.errorMessage = null;
    if (this.accType == 'user') {
      this.userService.authenticate(this.cuenta + "&evex&" + this.email, this.password, this);
    } else {
      this.userService.authenticate(this.email, this.password, this);
    }
  }

  cognitoCallback(message: string, result: any) {
    if (message) {
      //respuesta de error
      this.error = true;
      this.setErrorMessage(message);
    } else {
      // respuesta satisfactoria
      this.router.navigate(["/checkauth/"]);
    }
    this.loading = false;
  }

  /*
  Se encarga de manejar la autenticacion MFA cuando esta habilitada
  */
  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code == null || code.length === 0) {
        this.errorMessage = "Code is required";
        return;
      }
      this.errorMessage = null;
      callback(code);
    };
  }


  cancelMFA(): boolean {
    this.mfaStep = false;
    return false;   //necessary to prevent href navigation
  }

  isLoggedIn() {
    this.userService.isAuthenticated().subscribe((resp) => {
      this.isAuthenticated = resp.valid;
      //si la respuesta es valida entonces se redirige a la raiz para que app component maneje la redireccion
      if (resp.valid) {
        this.router.navigate(["/checkauth/"]);
      }

    }, (err) => {
      this.errorMessage = err.error;
      if (this.errorMessage === 'Password reset required for the user') {
        this.gotoNewPasword();
        return;
      }
      this.errorHandlerService.handleError(err);
    });
  }

  setErrorMessage(message) {
    let accName = this.accType == 'user' ? this.cuenta : "none";
    console.log(message);
    switch (message) {
      case 'User does not exist.': {
        this.errorMessage = "El usuario no existe";
        break;
      }
      case 'All fields are required': {
        this.errorMessage = "Todos los campos son requeridos";
        break;
      }
      case 'User is not confirmed.': {
        this.msgService.pop('info', 'No confirmado', 'El usuario no ha confirmado su cuenta');
        this.router.navigate(['../confirmRegistration', this.email], {relativeTo: this.activatedRoute});
        break;
      }
      case 'User needs to set password.': {
        this.msgService.pop('info', 'Nueva contraseña', 'Es necesario que establezca una nueva contraseña');
        console.log("redirecting to set new password");
        this.router.navigate(['../newPassword', this.email], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Password reset required for the user': {
        console.log("redirecting to set new password");
        this.router.navigate(['../newPassword', this.email], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Password attempts exceeded': {
        this.errorMessage = "Excedido el límite de intentos, intente de nuevo en unos minutos";
        break;
      }
      case 'Incorrect username or password.': {
        this.errorMessage = "La contraseña es incorrecta";
        break;
      }
      default: {
        this.errorMessage = message;
      }
    }
  }
}
