import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gifCarga',
  templateUrl: './gif-carga.component.html',
  styleUrls: ['./gif-carga.component.css']
})
export class GifCargaComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}
