//Modelo información requerida del emisor (usuario del sistema)
export class UserProfile {
  public name: string; // Nombre del usuario (80)
  public email: string; // Correo del usuario (60)
  public indIdentificationType: number;
  /* Indicador para el tipo de identificación Cédula Física=01, Cédula Jurídica=02, DIMEX=03, NITE=04*/
  public identificationValue: string; // Identificación (12)
  public comercialName: string; // Nombre del comercio, opc (80)
  public address: string; // Representa a otras señas, opc (160)
  public addressCRProvincia: number; // Provincia, opc (1)
  public addressCRCanton: number; // Cantón, opc (2)
  public addressCRDistrito: number; // Distrito, opc (2)
  public addressCRBarrio: number; // Barrio, opc (2)
  public phoneCountryCode: number; // Código del país, opc (3)
  public phoneNumber: number; // Número de teléfono
  public startPage: string; //Página default para inicio del usuario
  public username: string;
  public userID: string;

  constructor() {
    this.phoneCountryCode = 506;
    this.indIdentificationType = 1;
    this.startPage = '1';
  }

}
