/*Objeto para manejar la persistencia de las busquedas a travez de la plataforma */

import {GetQueryReceiptsData} from '../receipt/GetQueryReceiptsData'

export class IncomingReceipt {  //mensajesEnviados  //mensajesRecibidos

  public receiptList: any[];
  public page: number;
  public lastEvaluatedKey: boolean;
  public searchQuery: GetQueryReceiptsData;
  public lastUpdate: Date;

  constructor() {
    this.lastUpdate = new Date();
    this.receiptList = [];
    this.page = 1;
    this.lastEvaluatedKey = false;
    this.searchQuery = new GetQueryReceiptsData();

  }

}
