export class PeticionEnvioTransaccion {

  public archivoPDF: string;
  public comprobanteXML: string;
  public mhRespuestaXML: string;
  public indEnvio: number;
  public correoNotificacion: string;

  constructor() {
    this.indEnvio = 0;
    this.correoNotificacion = "";
  }
}

