import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {CognitoUserPool} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import {Observable} from "rxjs/Observable";
import {Observer} from "rxjs/Observer";
import {CognitoStorage} from "./cognito-storage";


/**
 * Flecha Roja Technologies
 * Fernando Aguilar
 * Servicio utilitario que expone funciones para facilitar la interaccion con AWS Cognito
 */

/**
 * Interfaces para el manejo de callbacks (en los metodos que los soportan)
 */
export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {

  //Constantes con la config
  public static _REGION = environment.region;
  public static _IDENTITY_POOL_ID = environment.identityPoolId;
  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;
  public static _POOL_DATA: any = {
    UserPoolId: CognitoUtil._USER_POOL_ID,
    ClientId: CognitoUtil._CLIENT_ID,
    Storage: new CognitoStorage()
  };
  //objeto que almacena las credenciales
  public cognitoCreds: AWS.CognitoIdentityCredentials;

  constructor() {
    this.setPoolData();

  }

  getUserPool() {
    if (environment.cognito_idp_endpoint) {
      CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
    }
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }

  setConfig(userPoolId: string, clientId: string) {
    CognitoUtil._USER_POOL_ID = userPoolId;
    CognitoUtil._CLIENT_ID = clientId;
    this.setPoolData();
  }

  setPoolData() {
    CognitoUtil._POOL_DATA = {
      UserPoolId: CognitoUtil._USER_POOL_ID,
      ClientId: CognitoUtil._CLIENT_ID,
      Storage: new CognitoStorage()
    }
  }

  /**
   * Obtiene acceso al usuario actual
   */
  getCurrentUser() {
    // console.log("getCurrentUser ",CognitoUtil._USER_POOL_ID,CognitoUtil._CLIENT_ID);
    return this.getUserPool().getCurrentUser();
  }

  /**
   * Provee acceso al CognitoIdentityCredentials en crudo
   * @param creds
   */

  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }


  /**
   * Obtiene las credenciales de Cognito
   */
  getCognitoCreds(): AWS.CognitoIdentityCredentials {
    return this.cognitoCreds;
  }


  /**
   * Este método toma un token de JWT y usa la configuración de AWS para construir un objeto de CognitoIdentityCredentials, lo almacena y lo retorna
   * @param idTokenJwt el token a almacenar
   */

  buildCognitoCreds(idTokenJwt: string): AWS.CognitoIdentityCredentials {
    let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
    if (environment.cognito_idp_endpoint) {
      url = environment.cognito_idp_endpoint + '/' + CognitoUtil._USER_POOL_ID;
    }
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
      Logins: logins
    };
    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    if (environment.cognito_identity_endpoint) {
      serviceConfigs.endpoint = environment.cognito_identity_endpoint;
    }
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }


  getCognitoIdentity(): string {
    return this.cognitoCreds.identityId;
  }

  /**
   * Obtiene un access token, retornando un Observable
   */
  getAccessToken(): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      if (this.getCurrentUser() != null) {
        this.getCurrentUser().getSession(function (err, session) {
          if (err) {
            console.log("CognitoUtil: Can't set the credentials:" + err);
            observer.error(null);
          }
          else {
            if (session.isValid()) {
              observer.next(session.getAccessToken().getJwtToken());
            }
          }
        });
      }
      else {
        observer.error(null);
      }
    });
  }

  /**
   * Obtiene un access token, recibe como parámetro un callback
   * @param callback
   */
  getAccessTokenCallback(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getAccessToken is null...returning");
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    }
    else {
      callback.callbackWithParam(null);
    }
  }

  /**
   * Obtiene un ID Token, retorna un observable sobre el cual se puede obtener el token
   * En caso de tener una sesion expirada entonces se refresca usando el refresh token
   */
  getIdToken(): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      if (this.getCurrentUser() != null) {
        this.getCurrentUser().getSession(function (err, session) {
          if (err) {
            console.log("CognitoUtil: Can't set the credentials:" + err);
            observer.error(null);
            observer.complete();
            return
          }
          else {
            if (session.isValid()) {
              observer.next(session.getIdToken().getJwtToken());
              observer.complete();
              return
            }
          }
        });
      }
      else {
        observer.error(null);
        observer.complete();
        return
      }
    });
  }


  /**
   * Obtiene un idToken, recibiendo como parámetro un callback el cual se invoca con el token
   * @param callback
   */
  getIdTokenCallback(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getIdToken is null...returning");
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            console.log("CognitoUtil: Got the id token, but the session isn't valid");
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }


  /**
   * Obtiene un refresh token, recibiendo como parámetro un callback el cual se invoca con el token
   * @param callback
   */
  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getRefreshToken is null...returning");
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }

        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  /**
   * Refresca el token
   */
  refresh(): void {
    this.getCurrentUser().getSession(function (err, session) {
      if (err) {
        console.log("CognitoUtil: Can't set the credentials:" + err);
      }

      else {
        if (session.isValid()) {
          console.log("CognitoUtil: refreshed successfully");
        } else {
          console.log("CognitoUtil: refreshed but session is still not valid");
        }
      }
    });
  }

  getPoolData() {
    return CognitoUtil._POOL_DATA;
  }
}
