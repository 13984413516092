import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {numeroSucursalValidator} from './numero-sucursal.validator';

@Directive({
    selector: '[numeroSucursal][ngModel]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumeroSucursalDirective, multi: true}]
})
export class NumeroSucursalDirective implements Validator {
    private validator = numeroSucursalValidator();

    validate(control: AbstractControl): { [key: string]: any } {
        return this.validator(control);
    }

    constructor() {
    }

}
