import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs/Observable";
import {UserProfile} from "../model";
import {UserLoginService} from "../service";

@Injectable()
export class UserAuthGuard implements CanActivate, CanActivateChild {

  constructor(public router: Router, public profile: UserProfile,
              public userLoginService: UserLoginService) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable(subscriber => {
      // console.log("CanActivateAdmin", state)
      this.userLoginService.isAuthenticated().subscribe(value => {
        //es valido cuando el usuario no tiene sesion activa
        let valid = !value.valid;
        console.log("AuthGuardAdminChild, valid", valid);
        // si la ruta no es valida
        if (!valid) {
          //se redirecciona app component para que este maneje el redirect al area respectiva
          this.router.navigate(["/checkauth/"]);
        }
        subscriber.next(valid);
        subscriber.complete();
      });
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivateChild(route, state);
  }

}
