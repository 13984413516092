import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../../service/profile.service';
import {ToasterService} from 'angular2-toaster';
import {UserProfileData} from '../../../model/profile/user-profile';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})

export class ConfigComponent implements OnInit {
  public profile: UserProfileData;
  public loadingData: boolean = false;
  public SubloadingData: boolean = false;
  public SubloadingDataATV: boolean = false;
  public urlComprobante: string;
  public urlNotificacion: string;

  public modalidadDefault_Activa:boolean = false; //0 recepción de todas las notificaciones por correo y plataforma (notificaciones de tipo INFO, WARNING, ERROR y rechazos de transacciones)
  public modalidadUno_Activa:boolean = false; //1 recepción de advertencias y errores por correo y plataforma (notificaciones de tipo WARNING, ERROR y rechazos de transacciones)
  public modalidadDos_Activa:boolean = false; //2 recepción de errores por correo y plataforma (notificaciones de tipo ERROR y rechazos de transacciones)
  public modalidadMenosUno_Activa:boolean = false; //-1 recepción de todas las notificaciones por solo plataforma



  constructor(public profileService: ProfileService, public toasterService: ToasterService) {
    this.profile = new UserProfileData();
    this.urlComprobante = this.urlNotificacion = "";
  }

  ngOnInit() {
    this.loadingData = true;
    let sub = this.profileService.getProfile().subscribe(
      (data) => {
        this.profile = data;
        this.changeModoNotificaciones(this.profile.confRecepcionNotificaciones);
        this.loadingData = false;
        
      },
      (error) => {
        this.loadingData = false;
        this.toasterService.pop('error', 'Error', "No fué posible la obtener la información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }


  saveConfiguration() {
    this.SubloadingData = true;
    let sub = this.profileService.updatePerfil(this.urlNotificacion, this.urlComprobante).subscribe(
      (data) => {
        this.toasterService.pop('success', 'Éxito', "Se ha guardado su perfil con éxito");
        this.SubloadingData = false;
      },
      (error) => {
        this.toasterService.pop('error', 'Error', "No se concretó la actualización");
        this.SubloadingData = false;
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  saveConfNotificaciones() {
    this.SubloadingData = true;
    let sub = this.profileService.updatePerfilConfNotificaciones(this.profile.confRecepcionNotificaciones).subscribe(
      (data) => {
        this.toasterService.pop('success', 'Éxito', "Se ha actualizado su configuració con éxito");
        this.SubloadingData = false;
      },
      (error) => {
        this.toasterService.pop('error', 'Error', "No se concretó la actualización");
        this.SubloadingData = false;
      },
      () => {
        sub.unsubscribe();
      }
    );
  }
  changeModoNotificaciones(modo:number){
    this.profile.confRecepcionNotificaciones = modo;

//    public modalidadDefault_Activa:boolean = false; //0 recepción de todas las notificaciones por correo y plataforma (notificaciones de tipo INFO, WARNING, ERROR y rechazos de transacciones)
//    public modalidadUno_Activa:boolean = false; //1 recepción de advertencias y errores por correo y plataforma (notificaciones de tipo WARNING, ERROR y rechazos de transacciones)
//    public modalidadDos_Activa:boolean = false; //2 recepción de errores por correo y plataforma (notificaciones de tipo ERROR y rechazos de transacciones)
//    public modalidadMenosUno_Activa:boolean = false; //-1 recepción de todas las notificaciones por solo plataforma
      this.modalidadMenosUno_Activa = false; 
      this.modalidadDefault_Activa = false;
      this.modalidadUno_Activa=false;
      this.modalidadDos_Activa=false;

    switch (modo) {
      case -1:{ this.modalidadMenosUno_Activa=true; break; }
      case 0:{ this.modalidadDefault_Activa=true; break; }
      case 1:{ this.modalidadUno_Activa=true; break; }
      case 2:{ this.modalidadDos_Activa=true; break; }

      default:{ this.modalidadDefault_Activa=true; break; }
        
    }
  }


}
