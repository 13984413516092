import {Component} from "@angular/core";
import {UserLoginService} from "./service/user-login.service";
import {NavigationEnd, Router} from "@angular/router";
import {OnInit} from "@angular/core/src/metadata/lifecycle_hooks";
import {UserProfile} from "./model";
import {ProfileService} from './service/';
import {ToasterConfig, ToasterService} from 'angular2-toaster/angular2-toaster';
import {environment} from "../environments/environment";
import {ConfigProvider} from "./service/config-provider.service";
import {forkJoin} from "rxjs/observable/forkJoin";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [ToasterService]
})
export class AppComponent implements OnInit {
  public toasterconfig: ToasterConfig = new ToasterConfig({
    tapToDismiss: true,
    timeout: 5000
  });

  constructor(public profile: UserProfile, public router: Router, public userLoginService: UserLoginService, private profileService: ProfileService, private configProvider: ConfigProvider) {

  }

  ngOnInit() {
    this.initUserCheck();
  }

  redirectToLogin() {
    this.router.navigate(["/evex/admin/auth/login"]);
  }

  redirectToAdminSite(navigationEnd: any) {
    this.configProvider.setConfigAsAdmin();
    this.profileService.getProfile();
    // if (navigationEnd.urlAfterRedirects == "/") {
      this.router.navigate(["/evex/admin/console/"]);
    // }

  }

  redirectToUserSite(navigationEnd: any) {
    this.configProvider.setConfigAsUser();
    this.profileService.getProfile();
    // if (navigationEnd.urlAfterRedirects == "/") {
      this.router.navigate(["/evex/user/console"]);
    // }
  }

  private initUserCheck() {
    let sub = this.router.events.filter(e => e instanceof NavigationEnd && e.url.includes("home")).subscribe((navigationEnd) => {
      forkJoin(
        this.userLoginService.isAuthenticatedWithConfig(environment.userPoolId_subusers, environment.clientId_subusers),
        this.userLoginService.isAuthenticatedWithConfig(environment.userPoolId, environment.clientId))
        .subscribe(
          (value: any[]) => {
            let isAdminLoggedIn = value.filter(val => val.pool != null && val.pool == environment.userPoolId).length == 1;
            let isUserLoggedIn = value.filter(val => val.pool != null && val.pool == environment.userPoolId_subusers).length == 1;

            if (isAdminLoggedIn && !isUserLoggedIn) {
              this.redirectToAdminSite(navigationEnd);
            }
            else if (!isAdminLoggedIn && isUserLoggedIn) {
              this.redirectToUserSite(navigationEnd);
            }
            else if (isAdminLoggedIn && isUserLoggedIn) {
              this.redirectToLogin();
            }
            else {
              console.log("Ninguno");
              this.redirectToLogin();
            }
          },
          err => {
            () => console.log('Error al intentar administrar la sesion');
          }
        );
    });
  }
}
