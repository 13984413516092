import {AbstractControl, ValidatorFn} from '@angular/forms';

const expression = /\d{1,3}/;

export function numeroSucursalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

        var min = false;
        var max = false;

        if (control.value != null && control.value != undefined) {
            max = control.value > 999;
            min = control.value < 0;
        }

        const patronCorrecto = expression.test(control.value);

        if (!min && !max && patronCorrecto) {
            return null;
        } else {
            var err = {};
            if (min) {
                err['min'] = true;
            }
            if (max) {
                err['max'] = true;
            }
            if (!patronCorrecto) {
                err['patronInvalido'] = true;
            }
            return err;
        }


    };
}
