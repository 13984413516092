import {Injectable} from '@angular/core';

@Injectable()
export class TransactionResponse {
  public indEstado: number;
  public idTransaccion: string;
  public fecha: number;

  constructor() {
  }
}
