export class PeticionPerfilATVConf {

  public usuarioATV: string;
  public contrasenaATV: string;
  public llaveCriptografica: string;

  constructor() {
  }

  /** */
}
