import {Component} from '@angular/core';
import {ConfigProvider} from "../../service/config-provider.service";
import {Router} from "@angular/router";
import {UserProfile} from "../../model";
import {CognitoUtil, UserLoginService} from "../../service";

@Component({
  selector: 'app-dashboard',
  providers: [ConfigProvider],
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent {

  constructor(private router: Router, public profile: UserProfile, public userLoginService: UserLoginService, public cognito: CognitoUtil) {

  }


}
