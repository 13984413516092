export class ReceiptTypesList {

  public static tiposDocumento =
    [
      {
        "label": "Cualquier tipo de archivo",
        "value": 0
      },
      {
        "label": "Factura Electronica",
        "value": 1
      },
      {
        "label": "Nota de Debito",
        "value": 2
      },
      {
        "label": "Nota de Credito",
        "value": 3
      },
      {
        "label": "Tiquete Electrónico",
        "value": 4
      },
      {
        "label": "Mensaje Receptor",
        "value": 5
      },

      {
        "label": "Factura de Compra",
        "value": 6
      },
      {
        "label": "Factura de Exportación",
        "value": 7
      },


    ]
}
