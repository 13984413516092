import {Component, OnInit} from '@angular/core';
import {RespuestaComercio} from '../../../model/index';
import {ProfileService} from '../../../service/index';
import {ToasterService} from 'angular2-toaster/angular2-toaster';

@Component({
  selector: 'user-profile',
  templateUrl: './user-edit.component.html',
  providers: [ProfileService]
})


export class UserEditComponent implements OnInit {
  /*============================== */
  public businessObjectData: RespuestaComercio = new RespuestaComercio();
  public phoneAddStr: string = "";
  public facilidafAddStr: string = "";
  public lugarAddStr: string = "";

  /*============================== */
  public direccion = "";
  public dataFotoPerfil = "";
  public loadingData: boolean = false;
  public primiumAccess = false;
  public informacionPublica: boolean = false;
  /*================================= */

  constructor(public profileService: ProfileService, public toasterService: ToasterService) {
  }


  ngOnInit() {
    this.loadingData = true;
    this.businessObjectData = {informacionPublica: 0, nombreComercio: "", razonSocial: "", tiposPago: "", correoElectronico: "", facilidades: [], lugares: [], telefonos: []};
    let sub = this.profileService.getBusinessProfile().subscribe(
      (data) => {
        console.log(data);
        this.businessObjectData = data;
        this.loadingData = false;
        if (this.businessObjectData.informacionPublica == 0) {
          this.informacionPublica = false;
        }
        if (this.businessObjectData.informacionPublica == 1) {
          this.informacionPublica = true;
        }
        setTimeout(() => {
        }, 600);
      },
      (error) => {
        console.log(error.message);
        this.businessObjectData = {informacionPublica: 0, nombreComercio: "", razonSocial: "", tiposPago: "", correoElectronico: "", facilidades: [], lugares: [], telefonos: []};
        this.loadingData = false;
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  addString(table: string) {

  }

  saveProfileBusiness() {

    if (this.businessObjectData.informacionPublica) {
      this.businessObjectData.informacionPublica = 1;
    } else {
      this.businessObjectData.informacionPublica = 0;
    }

    let sub = this.profileService.updatePerfilComercio(this.businessObjectData).subscribe(
      (data) => {
        console.log(data);
        this.toasterService.pop('success', 'Éxito', "Información Pública Actualizada Correctamente");
        setTimeout(() => {
        }, 600);
      },
      (error) => {
        console.log("==" + error.message);
        this.toasterService.pop('error', 'Error', "No fué posible la solicitud de archivos u información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }


  setPublicInfo() {
    this.informacionPublica = true;
  }

  setPrivateInfo() {
    this.informacionPublica = false;
  }


  addPhone() {
    this.businessObjectData.telefonos.push(this.phoneAddStr);
    this.phoneAddStr = "";
  }

  deletePhone(itemIndex: number) {
    this.businessObjectData.telefonos.splice(itemIndex, 1);
  }

  addFacilidad() {
    this.businessObjectData.facilidades.push(this.facilidafAddStr);
    this.facilidafAddStr = "";
  }

  deleteFacilidad(itemIndex: number) {
    this.businessObjectData.facilidades.splice(itemIndex, 1);
  }

  addLugar() {
    this.businessObjectData.lugares.push(this.lugarAddStr);
    this.lugarAddStr = "";
  }

  deleteLugar(itemIndex: number) {
    this.businessObjectData.lugares.splice(itemIndex, 1);
  }


}


