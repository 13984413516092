import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Import Containers
import {AdminLayoutComponent, UserLayoutComponent} from './containers';
import {AdminComponent} from "./containers/admin-layout/admin.component";
import {UserComponent} from "./containers/user-layout/user.component";
import {AuthLayoutComponent} from "./containers/auth-layout/auth-layout.component";
import {AppComponent} from "./app.component";
import {AdminGuard} from "./guards/admin.guard";
import {AdminAuthGuard} from "./guards/admin-auth.guard";
import {UserAuthGuard} from "./guards/user-auth.guard";
import {UserGuard} from "./guards/user.guard";
import {CheckAuthComponent} from "./components/auth/check-auth.component";

export const routes: Routes = [
  {path: '', redirectTo:"evex", pathMatch:"full"},
  {path: 'checkauth', component:CheckAuthComponent},
  {
    path: 'evex',
    data: {
      title: 'EVEx'
    },
    children: [
      {path: '', redirectTo: "admin", pathMatch: "full"},
      {
        path: 'admin',
        component: AdminComponent,
        data: {
          title: 'Admin'
        },
        children: [
          {path: '', redirectTo: "console", pathMatch: "full"},
          {
            path: 'console',
            component: AdminLayoutComponent,
            canActivateChild: [AdminGuard],
            data: {
              title: 'EVEx'
            },
            children: [
              {path: '', redirectTo: "comprobantes", pathMatch: "full"},
              {
                path: 'comprobantes',
                loadChildren: './module/receipt/receipt.module#ReceiptModule'
              },
              {
                path: 'dashboard',
                loadChildren: './module/dashboard/dashboard.module#DashboardModule'
              },
              {
                path: 'perfil',
                loadChildren: './module/profile/profile.module#ProfileModule'
              },
              {
                path: 'envio',
                loadChildren: './module/sendReceipts/sendReceipts.module#ReceiptSendModule'
              },
              {
                path: 'transaccion',
                loadChildren: './module/transactions/transactions.module#TransactionsModule'
              },
              {
                path: 'mensajesreceptor',
                loadChildren: './module/receiver-message/receiver-message.module#ReceiverMessageModule'
              },
              {
                path: 'usuario',
                loadChildren: './module/usuarioSecundario/usuarioSecundario.module#UsuarioSecundarioModule'
              },
              {
                path: 'reportes',
                loadChildren: './module/reportes/reportes.module#ReportesModule'
              }
            ]
          },
          {
            path: 'auth',
            component: AuthLayoutComponent,
            canActivateChild: [AdminAuthGuard],
            data: {
              title: 'Auth'
            },
            loadChildren: './module/auth/auth.module#AuthModule',
          }
        ]
      }, {
        path: 'user',
        component: UserComponent,
        data: {
          title: 'Usuarios'
        },
        children: [
          {path: '', redirectTo: "console", pathMatch: "full"},
          {
            path: 'auth',
            component: AuthLayoutComponent,
            canActivateChild: [UserAuthGuard],
            data: {
              title: 'Auth'
            },
            loadChildren: './module/auth/auth.module#AuthModule',
          },
          {
            path: 'console',
            component: UserLayoutComponent,
            canActivateChild: [UserGuard],
            data: {
              title: 'Auth'
            },
            children: [
              {path: '', redirectTo: "comprobantes", pathMatch: "full"},
              {
                path: 'comprobantes',
                loadChildren: './module/receipt/receipt.module#ReceiptModule'
              },
              {
                path: 'perfil',
                loadChildren: './module/profile/profile.module#ProfileModule'
              },
              {
                path: 'dashboard',
                loadChildren: './module/dashboard/dashboard.module#DashboardModule'
              },
              {
                path: 'envio',
                loadChildren: './module/sendReceipts/sendReceipts.module#ReceiptSendModule'
              },
              {
                path: 'transaccion',
                loadChildren: './module/transactions/transactions.module#TransactionsModule'
              },
              {
                path: 'mensajesreceptor',
                loadChildren: './module/receiver-message/receiver-message.module#ReceiverMessageModule'
              },
              {
                path: 'reportes',
                loadChildren: './module/reportes/reportes.module#ReportesModule'
              }
            ]
          },
        ]
      }
    ]
  }
];

//{,enableTracing:true}
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
