import {Injectable, OnInit} from '@angular/core';
import {ProfileService} from './profile.service';

@Injectable()
export class GlobalUser implements OnInit {

  //Todo parametro de configuración
  private estatus: boolean = false; //Control de la actualizacion de los atributos.
  private indVencimientoMH: number; //Cantidad de dias despues de la fecha de creacion para la visualizacion del indicador de vencimiento del plazo para aceptacion rechazo.

  constructor(public profileService: ProfileService) {
    this.indVencimientoMH = 3;
  }

  ngOnInit(): void {
    let sub = this.profileService.getProfile().subscribe(
      (data) => {
        if (data) {

          if (data.parametrosUsuario && data.parametrosUsuario.indVencimientoMH) {
            this.indVencimientoMH = data.parametrosUsuario.indVencimientoMH; //Default de 8 dias
            this.estatus = true;
          } else {
            this.indVencimientoMH = 8; //Default de 5 dias
          }

        }
        setTimeout(() => {
        }, 500);
      },
      (error) => {
        //  this.toasterService.pop('error', 'Error', "No fué posible la solicitud de archivos u información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  //Set - Gets
  getIndVencimientoMH() {
    return this.indVencimientoMH;
  }

  setIndVencimientoMH(indicadorVencimiento: number) {
    this.indVencimientoMH = indicadorVencimiento;
    this.estatus = true;
  }

  getEstatus() {
    return this.estatus;
  }

}

















