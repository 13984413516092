import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';

@Component({
  selector: 'custom-InputFile',
  templateUrl: './cInputFile.component.html',
  styleUrls: ['./cInputFile.component.css'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomInputFileComponent), multi: true},
    {
      provide: NG_VALIDATORS, useExisting: forwardRef(() => CustomInputFileComponent)
    }],
})

export class CustomInputFileComponent implements ControlValueAccessor, Validator {
  valid: boolean;
  name: string;
  /*===============Atributos====================*/
  files: FileList;
  /*============================================*/
  @ViewChild('Input') pdfInput: Input;
  @Input() disabled: boolean = false;
  @Input() acceptFile: string;
  onChange = (files: FileList) => {
  };

  constructor() {
  }

  public _fileName: string = "";

  get fileName(): string {
    return this._fileName;
  }

  private _fileType: string = "";

  get fileType(): string {
    return this._fileType;
  }

  private _fileValue: string = "";

  get fileValue(): string {
    return this._fileValue;
  }

  validate(c: AbstractControl): { [key: string]: any; } {
    return (this.valid) ? null : {
      dateParseError: {
        valid: false,
      },
    };
  }
  // Permitir a Angular actualizar el input.

  // Update del modelo y cambios en la vista aqui.
  writeValue(files: FileList): void { //Nuestro input recibe files (el arreglo de los archivos)
    this.files = files;
    this.onChange(this.files);
  }

  // Permitir a Angular registrar la funcion cuando el modelo cambia.
  // Guardar la funcion como un atributo para usar despues
  registerOnChange(fn: (files: FileList) => void): void {
    this.onChange = fn;
  }

  // Permitir a Angular registrar la funcion cuando el modelo se ha tocado.
  // Guardar la funcion como un atributo para usar despues
  registerOnTouched(fn: any): void {
    //nope
  }

  //permitir a Angular desactivar el input
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // alert("VALUES "+file.name+" "+file.type+" ");
        this._fileName = file.name;
        this._fileType = file.type;
        this._fileValue = (<string>reader.result).split(',')[1];
      };
    }
  }

  /*================================================================ */


}
