import {Component, OnInit} from "@angular/core";
import {ConfigProvider} from "../../service/config-provider.service";

@Component({
  selector: 'app-user',
  template: '<router-outlet></router-outlet>'
})
export class UserComponent implements OnInit {

  constructor(private configProvider: ConfigProvider) {

  }

  ngOnInit(): void {
    this.configProvider.setConfigAsUser();
  }
}
