import {Directive, forwardRef} from "@angular/core";
import {AbstractControl, NG_ASYNC_VALIDATORS, Validator} from "@angular/forms";
import {Observable} from "rxjs/Rx";
import {UserRegistrationService} from '../../service/user-registration.service';

@Directive({
  selector: "[asyncUserValidator]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => UsernameValidator), multi: true
    }
  ]
})
export class UsernameValidator implements Validator {
  constructor(private userService: UserRegistrationService) {

  }

  validate(c: AbstractControl): Promise<{ [key: string]: any }> | Observable<{ [key: string]: any }> {
    return this.validateUniqueUsernameObservable(c.value).debounceTime(500).distinctUntilChanged().first();
  }

  validateUniqueUsernameObservable(username: string) {
    return new Observable(observer => {
      let sub = this.userService.checkUsernameAvailability(username).subscribe(
        (data) => {
          // if (data.valid == true) {
          //     return {};

        },
        (err) => {
        },
        () => {
          sub.unsubscribe()
        });
    });
  }
}
