import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from '../../components/profile/profile.component';
import {UserDataComponent} from '../../components/profile/user-data/user.component';
import {UserEditComponent} from '../../components/profile/user-edit/user-edit.component';
import {ConfigComponent} from '../../components/profile/config/config.component';
import {ATVComponent} from '../../components/profile/atv/atv.component';

const routes: Routes = [{
  path: '', component: ProfileComponent, children: [
    {path: 'general', component: UserDataComponent},
    {path: 'atv', component: ATVComponent},
    {path: 'editar', component: UserEditComponent},
    {path: 'configuracion', component: ConfigComponent}


  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule {
}


