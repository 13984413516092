import {Component, OnInit, ViewChild} from '@angular/core';
import {PeticionPerfilATVConf, UserProfileData} from '../../../model/index';
import {ProfileService} from '../../../service/index';
import {CustomInputFileComponent} from '../../custom-components/inputFile/cInputFile.component';

import {ToasterService} from 'angular2-toaster/angular2-toaster';

@Component({
  selector: 'user-profile',
  templateUrl: './user.component.html',
  providers: [ProfileService]
})

export class UserDataComponent implements OnInit {
  public atvConf: PeticionPerfilATVConf;
  public viewATVCongif: boolean = false;
  public isFullATV: boolean = false;
  public profile: UserProfileData;
  public profileName: string = "";
  public profileEmail: string = "";
  public loadingData: boolean = false;
  public SubloadingData: boolean = false;
  public SubloadingDataATV: boolean = false;
  @ViewChild('Key_fileInput') pdfInput: CustomInputFileComponent;
  private username: string;

  constructor(public profileService: ProfileService, public toasterService: ToasterService,) {
    this.profile = new UserProfileData();
  }

  ngOnInit() {

    this.loadingData = true;
    this.atvConf = {usuarioATV: "", contrasenaATV: "", llaveCriptografica: ""};

    let sub = this.profileService.getProfile().subscribe(
      (data) => {

        this.profile = data;
        this.profileName = this.profile.nombreCompleto;
        this.profileEmail = this.profile.correoElectronico;

        if (data.nombreUsuario && data.llaveCriptografica && data.nombreUsuario != "" && data.llaveCriptografica != "") {
          this.isFullATV = true;

        } else {
          this.isFullATV = false;
        }

        this.loadingData = false;

        setTimeout(() => {

        }, 5000);
      },
      (error) => {
        this.loadingData = false;
        this.toasterService.pop('error', 'Error', "No fué posible la solicitud de archivos u información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  toggleViewConf() {
    if (this.viewATVCongif) {
      this.viewATVCongif = false;
    } else {
      this.viewATVCongif = true;
    }
  }

  saveSimpleProfile() {
    let optionals = {};
    this.SubloadingData = true;
    let sub = this.profileService.updatePerfilSimpleCuenta(this.profileName, this.profileEmail).subscribe(
      (data) => {
        this.toasterService.pop('success', 'Éxito', "Se ha guardado su perfil con éxito");
        this.SubloadingData = false;

        setTimeout(() => {
        }, 600);
      },
      (error) => {
        
        if (error.status == 400) {//401
          this.toasterService.pop('error', 'Error', "No autorizado");
        } else if (error.status == 404) {
          this.toasterService.pop('error', 'Error', "Usuario no encontrado");
        }else if (error.status == 422) {
          this.toasterService.pop('error', 'Error', "El correo esta siendo utilizado por otro usuario.");
        }
        else {
          this.toasterService.pop('error', 'Error', "No se concretó la actualización");
        } 
        this.SubloadingData = false;
        
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  saveConfiguration() {
    this.atvConf.llaveCriptografica = this.pdfInput.fileValue;
    //  alert("Saved"+JSON.stringify(this.atvConf, null, 2));
    this.SubloadingDataATV = true;
    let sub = this.profileService.updatePerfilATV(this.atvConf).subscribe(
      (data) => {
        this.toasterService.pop('success', 'Éxito', "Se guargaron sus credenciales correctamente");
        this.SubloadingDataATV = false;
        this.viewATVCongif = false;
        this.isFullATV = true;
        setTimeout(() => {
        }, 600);
      },
      (error) => {
        this.SubloadingDataATV = false;
        this.toasterService.pop('error', 'Error', "No se concretó la actualización");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }


}
