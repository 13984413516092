import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {ConfigProvider} from "../../service/config-provider.service";

/**
 * Componente encargado de manejar el olvido de password
 */
@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  public email: string;
  public errorMessage: string;
  public loading = false;
  public accType: string = null;

  constructor(public router: Router,
              private route: ActivatedRoute,
              public userService: UserLoginService,
              private configProvider: ConfigProvider) {
    this.errorMessage = null;
  }

  onNext() {
    this.accType = this.configProvider.getConfig().indTipo;
    this.errorMessage = null;
    this.userService.forgotPassword(this.email, this);
  }

  normalizeStrings() {
    this.email = this.email.trim();
    this.email = this.email.normalize("NFC");
  }

  cognitoCallback(message: string, result: any) {
    if (message == null && result == null) { //error
      this.router.navigate(['../forgotPassword', this.email],{relativeTo: this.route});
    } else { //success
      this.errorMessage = message;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params);
    });
  }
}
