import {Component} from "@angular/core";

@Component({
  selector: 'app-user',
  templateUrl: 'user-layout.component.html'
})
export class UserLayoutComponent {


}
