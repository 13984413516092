import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Rx';
import {UserProfile} from '../model';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {PeticionPerfilATVConf, PeticionPutPerfilCuenta, RespuestaComercio} from '../model/index';
import {environment} from "../../environments/environment";

@Injectable()
export class ProfileService {

  public profile: Subject<UserProfile> = new Subject<UserProfile>();
  private api_url = environment.api_url;
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.headers.append("Content-Type", "application/json");
  }

  //----------------------------------------------------------


  updatePerfilSimpleCuenta(name: string, email: string) {
    let params = {};
    params["nombreCompleto"] = name;
    params["correoElectronico"] = email;
    return this.http.put(this.api_url + "/perfil/cuenta", params, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  updatePerfilATV(params: PeticionPerfilATVConf) {
    return this.http.put(this.api_url + "/perfil/atv", params, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  updatePerfilComercio(params: RespuestaComercio) {
    return this.http.put(this.api_url + "/perfil/publico", params, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  updatePerfil(urlNotificacion: string, urlComprobante: string): any {
    return this.http.put(this.api_url + "/perfil/cuenta", {"urlEventoComprobanteEntrante": urlComprobante}, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }
  updatePerfilConfNotificaciones(confNotificacion:number): any {
    return this.http.put(this.api_url + "/perfil/cuenta", {"confRecepcionNotificaciones": confNotificacion}, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  getProfile(): any {
    var options = new HttpParams();
    return this.http.get(this.api_url + "/perfil/cuenta", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessProfile(): any {
    var options = new HttpParams();
    return this.http.get(this.api_url + "/perfil/publico", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePerfilCuenta(basicProfile: PeticionPutPerfilCuenta, optionalsParams: any) {
    if (optionalsParams["direccion"]) {
      basicProfile["direccion"] = optionalsParams["direccion"];
    }
    if (optionalsParams["dataFotoPerfil"]) {
      basicProfile["dataFotoPerfil"] = optionalsParams["dataFotoPerfil"];
    }
    return this.http.put(this.api_url + "/perfil/cuenta", basicProfile, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }


  private handleError(error: any) {
    return new ErrorObservable(error);
  };

}
