import {Component, OnInit} from '@angular/core';
import {ToasterService} from "angular2-toaster/angular2-toaster";
import {UsuarioSecundarioLista} from '../../../model/usuarioSecundario/usuarioSecundarioLista';
import {UsuarioSecundarioService} from '../../../service/EVEx-Facturador/usuarioSecundario/usuarioSecundario.service';

@Component({
    selector: 'app_usuario-lista',
    templateUrl: './usuario-lista.component.html',
    styleUrls: ['./usuario-lista.component.css'],
    providers: [ToasterService, UsuarioSecundarioService]
})

export class UsuarioListaComponent implements OnInit {
    public Autorizado: boolean = true;
    public loadingData: boolean = false;
    public usuarioSecundarioLista: UsuarioSecundarioLista[];
    public refreshDate: Date;
    public busquedaGeneral: string; //String de busqueda
    /*======Paginación========*/
    public tamannioPagina: number = 9;
    public numeroPagina: number = 1;
    public totalPaginas: number = 1;


    constructor(public toasterService: ToasterService, public usuarioSecundarioService: UsuarioSecundarioService) {
        this.usuarioSecundarioLista = [];
    }

    ngOnInit() {
        this.obtenerListaUsuarios();
    }

    obtenerListaUsuarios() {
        this.loadingData = true;
        this.refreshDate = new Date();
        this.usuarioSecundarioService.queryUsuarioSecundario(this.numeroPagina, this.busquedaGeneral).subscribe((data) => {
            this.loadingData = false;
            if (data.length > 0) {
                Array.prototype.push.apply(this.usuarioSecundarioLista, data);//concatenar arrays
                this.totalPaginas = this.usuarioSecundarioLista.length / this.tamannioPagina;
            } else {

            }
        }, (error) => {
            if (error.status == 401) {// 401 No autorizado
                this.Autorizado = false;
            } else if (error.status == 404) { //No encontrado
                //
            }
            else {
                this.toasterService.pop('error', 'No hay usuarios secundarios', 'Hubo un error al optener usuarios secundarios');
            }
            this.loadingData = false;
        });
    }

    cambioDePagina(nuevaPagina): void {
        if (nuevaPagina > 0) {
            this.numeroPagina = nuevaPagina;
        }
    }

    /*===== Fin Metodos de Paginación Paginacion =======*/
}