export class GetQueryReceiptsData {
  public ultimoRegistroFecha: number;
  public ultimoRegistroCN: string;

  public accion: string;
  public indTipo: number;

  public claveNumerica: string;
  public emisor: string;
  public receptor: string;

  public indEstado: number;

  constructor() {
    this.ultimoRegistroFecha = 0;
    this.ultimoRegistroCN = "";

    this.accion = "";
    this.indTipo = 0;

    this.claveNumerica = "";
    this.emisor = "";
    this.receptor = "";

    this.indEstado = 0;
  }
}

