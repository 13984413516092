import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";

/**
 * Componente encargado de sacar a los usuarios de sesion
 */
@Component({
  selector: 'logout-component',
  templateUrl: 'logout.component.html'
})

export class LogoutComponent {

  constructor(public router: Router,
              public userService: UserLoginService) {

  }

  logout() {
    this.userService.isAuthenticated().subscribe((response) => {
      console.log("ok", response);
      if (response.valid) {
        this.userService.logout();

      }
      this.router.navigate(['/evex/']);
    }, (error) => {
      console.log("Error");
      this.router.navigate(['/evex/']);
    });
    console.log("logout");
  }
}
