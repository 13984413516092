import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ProfileService, UserLoginService} from "../../service";
import {ConfigProvider} from "../../service/config-provider.service";

@Component({
    selector: 'check-auth',
    template: ``,
    styles: [``],
    providers: []
})

export class CheckAuthComponent implements OnInit {
    confirmationCode: string;
    email: string;
    errorMessage: string;
    loading = false;
    private sub: any;

    constructor(public userLoginService: UserLoginService,
                private profileService: ProfileService,
                private configProvider: ConfigProvider,
                public router: Router) {

    }

    ngOnInit() {
        console.log("Checking auth...");
        this.initUserCheck();
    }

    redirectToLogin() {
        this.router.navigate(["/evex/admin/auth/login"]);
    }

    redirectToAdminSite() {
        this.configProvider.setConfigAsAdmin();
        this.profileService.getProfile();
        this.router.navigate(["/evex/admin/console/"]);
    }

    redirectToUserSite() {
        this.configProvider.setConfigAsUser();
        this.profileService.getProfile();
        this.router.navigate(["/evex/user/console"]);
    }

    private initUserCheck() {
        // Se verifica si esta autenticdo como admin
        this.userLoginService.isAuthenticatedWithConfig(environment.userPoolId, environment.clientId).subscribe(
            (value: any) => {
                console.log(value);
                 if(value.pool != null && value.pool == environment.userPoolId){
                     console.log("Autenticado como admin...");
                     this.redirectToAdminSite();

                 } else {
                   console.log("No Autenticado como admin...");
                     //se verifica si esta autenticado como usuario
                     this.userLoginService.isAuthenticatedWithConfig(environment.userPoolId_subusers, environment.clientId_subusers).subscribe(
                         value => {
                             console.log("Autenticado como subuser");
                             this.redirectToUserSite();
                             return
                         },
                         error => {
                             console.log("No autenticado como subuser...");
                             this.redirectToLogin();
                             return
                         }
                     )
                 }
            },
           (err) => {
               console.log('No autenticado como admin...', err);
               //se verifica si esta autenticado como usuario
               this.userLoginService.isAuthenticatedWithConfig(environment.userPoolId_subusers, environment.clientId_subusers).subscribe(
                   value => {
                       console.log("Autenticado como subuser");
                       this.redirectToUserSite();
                       return
                   },
                   error => {
                       console.log("No autenticado como subuser...");
                       this.redirectToLogin();
                       return
                   }
               );

            }
        );
    }

}





