import {Injectable} from "@angular/core";
import {ICognitoStorage} from "amazon-cognito-identity-js";
import {environment} from "../../environments/environment";


@Injectable()
export class CognitoStorage implements ICognitoStorage {

  // limpia el almacenamiento
  public clear(): void {
    //console.log("clear ");
    var keyValuePairs = document.cookie.split(';');
    for (var i = 0; i < keyValuePairs.length; i++) {
      var name = keyValuePairs[i].substring(0, keyValuePairs[i].indexOf('='));
      this.removeItem(name);
    }
  }

  //obtiene un item
  public getItem(key: string): string {

    //console.log("getItem "+key);
    var name = key + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        //console.log("cookie encontrado:",c)
        return c.substring(name.length, c.length);
      }
    }

    return "";

    // //si el cookie no existe la creo
    // this.setItem(key,"");
    // return this.getItem(key);
  }

  //elimina un item
  public removeItem(key: string): void {
    //console.log("removeItem "+key);
    var d = new Date();
    d.setTime(253376798293000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + "" + ";" + expires + ";path=/;domain=" + environment.domain;
  }

  //establece un item
  public setItem(key: string, value: string): void {
    //console.log("setItem "+key);
    var d = new Date();
    d.setTime(253376798293000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/;domain=" + environment.domain;
  }

}
