import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'loadingGif',
  templateUrl: './loadingcircle.component.html'
})
export class LoadingGif implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
