import {Component, OnInit} from "@angular/core";
import {ConfigProvider} from "../../service/config-provider.service";

@Component({
  selector: 'app-admin',
  template: '<router-outlet></router-outlet>'
})
export class AdminComponent implements OnInit {

  constructor(private configProvider: ConfigProvider) {

  }

  ngOnInit(): void {
    this.configProvider.setConfigAsAdmin();
  }

}
