import {Component, OnInit, ViewChild} from '@angular/core';
import {ProfileService} from '../../../service/profile.service';
import {ToasterService} from 'angular2-toaster';
import {UserProfileData} from '../../../model/profile/user-profile';
import {CustomInputFileComponent} from '../../custom-components/inputFile/cInputFile.component';
import {PeticionPerfilATVConf} from '../../../model/index';


@Component({
  selector: 'app-config',
  templateUrl: './atv.component.html',
  providers: [ProfileService]

})
export class ATVComponent implements OnInit {

  public atvConf: PeticionPerfilATVConf;
  public viewATVCongif: boolean = false;
  public isFullATV: boolean = false;

  public profile: UserProfileData;
  public loadingData: boolean = false;
  public SubloadingDataATV: boolean = false;
  @ViewChild('Key_fileInput') pdfInput: CustomInputFileComponent;

  constructor(public profileService: ProfileService, public toasterService: ToasterService) {

  }

  ngOnInit() {
    this.loadingData = true;
    this.atvConf = {usuarioATV: "", contrasenaATV: "", llaveCriptografica: ""};

    let sub = this.profileService.getProfile().subscribe(
      (data) => {

        this.profile = data;
        if (data.nombreUsuario && data.llaveCriptografica && data.nombreUsuario != "" && data.llaveCriptografica != "") {
          this.isFullATV = true;
        } else {
          this.isFullATV = false;
        }

        this.loadingData = false;

        setTimeout(() => {

        }, 5000);
      },
      (error) => {
        this.loadingData = false;
        this.toasterService.pop('error', 'Error', "No fué posible la solicitud de archivos u información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  toggleViewConf() {
    if (this.viewATVCongif) {
      this.viewATVCongif = false;
    } else {
      this.viewATVCongif = true;
    }
  }

  saveConfiguration() {
    this.atvConf.llaveCriptografica = this.pdfInput.fileValue;
    //  alert("Saved"+JSON.stringify(this.atvConf, null, 2));
    this.SubloadingDataATV = true;
    let sub = this.profileService.updatePerfilATV(this.atvConf).subscribe(
      (data) => {
        this.toasterService.pop('success', 'Éxito', "Se guargaron sus credenciales correctamente");
        this.SubloadingDataATV = false;
        this.viewATVCongif = false;
        this.isFullATV = true;
        setTimeout(() => {
        }, 600);
      },
      (error) => {
        this.SubloadingDataATV = false;
        this.toasterService.pop('error', 'Error', "No se concretó la actualización");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

}
