export class PremiumRequestData {

  public nombreCompleto: string;
  public codigoTelefonico: number;
  public telefono: string;
  public correoElectronico: string;
  public comentario: string;

  constructor() {
  }

  /** */
}
