export class AccessTokenCallback implements Callback {
  constructor(public obj: any) {

  }

  callback() {

  }

  callbackWithParam(result) {
    this.obj.authz.accessToken = result;
  }
}

export class IdTokenCallback implements Callback {
  constructor(public obj: any) {

  }

  callback() {

  }

  callbackWithParam(result) {
    this.obj.authz.idToken = result;
  }
}

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}
