import { Component, OnInit } from '@angular/core';
import { ToasterService } from "angular2-toaster/angular2-toaster";

import { ActivatedRoute } from "@angular/router";
import { UsuarioSecundarioService } from '../../../service/EVEx-Facturador/usuarioSecundario/usuarioSecundario.service';
import { UsuarioSecundarioGet } from '../../../model/usuarioSecundario/usuarioSecundarioGet'
import { Permisos } from '../../../catalogs/permisos';
import { ConsecutivoUsuarioSecundario } from '../../../model/usuarioSecundario/consecutivo';

@Component({
    selector: 'app_usuario-detalle',
    templateUrl: './usuario-detalle.component.html',
    styleUrls: ['./usuario-detalle.component.css'],
    providers: [ToasterService]
})

export class UsuarioDetalleComponent implements OnInit {

    public Autorizado: boolean = true;
    public loadingData: boolean = false;
    public refreshDate: Date;
    private idUsuario: string;
    public usuarioSecundario: UsuarioSecundarioGet;
    public usuarioSecundarioEditar: UsuarioSecundarioGet;
    public mostrarEdicion: boolean = false;
    public usuarioDeshabilitado: boolean = false;
    public usuarioHabilitado: boolean = false;
    public estado: string;
    public nombreUsuario: string;
    public claveReiniciada: boolean = false;
    public listaPermisos: string[] = [];
    public listaPermisosDisponibles: string[] = [];
    public edición: boolean;
    public numTerminal: number;
    public numSucursal: number;
    public consecutivo: ConsecutivoUsuarioSecundario;

    constructor(public usuarioSecundarioService: UsuarioSecundarioService, public toasterService: ToasterService, public route: ActivatedRoute) {
        this.refreshDate = new Date();
        this.listaPermisos = Permisos.permisos;
        this.listaPermisosDisponibles = [];
        this.consecutivo = { numSucursal: this.numSucursal, numTerminal: this.numTerminal };

    }

    ngOnInit() {
        this.idUsuario = this.route.snapshot.paramMap.get('id');
        this.obtenerUsuarioSecundario();
    }

    obtenerUsuarioSecundario() {
        this.loadingData = true;
        this.usuarioSecundarioService.getUsuarioSecundario(this.idUsuario).subscribe((data) => {

            this.loadingData = false;
            this.usuarioSecundario = data;
            this.usuarioSecundarioEditar = JSON.parse(JSON.stringify(data));//nueva copia del cliente.
            if (!this.usuarioSecundario.confConsecutivo) {
                this.usuarioSecundarioEditar.confConsecutivo = this.consecutivo;
            }
            if (this.usuarioSecundario.permisos) {
                this.generarListaPermisosDisponibles();
            } else {
                this.listaPermisosDisponibles = this.listaPermisos;
            }

        }, (error) => {
            if (error.status == 401) {//401
                this.Autorizado = false;
            } else if (error.status == 404) {
                //
            }
            else {
                this.toasterService.pop('error', 'No hay usuario', 'Hubo un error al optener el usuario');
            }
            this.loadingData = false;

        });
    }

    agregarPermiso(permiso) {
        this.edición = true;
        var existe = false;
        var indice;
        if (this.usuarioSecundarioEditar.permisos.length == 0) {
            this.usuarioSecundarioEditar.permisos.push(permiso);
            this.toasterService.pop('Success', 'Permiso agregado', 'El permiso ha sido agregado exitosamente');

            this.listaPermisosDisponibles.forEach(element => {
                if (permiso == element) {
                    indice = this.listaPermisosDisponibles.indexOf(element);
                }
            });
            if (!existe) {
                if (indice > -1) {
                    this.listaPermisosDisponibles.splice(indice, 1);
                }
            }

        } else {
            this.usuarioSecundarioEditar.permisos.forEach(elemento => {
                if (permiso == elemento) {
                    existe = true;
                    this.toasterService.pop('Warning', 'Permiso existente', 'El usuario ya tiene el permiso asignado');

                }
            });

            this.listaPermisosDisponibles.forEach(element => {
                if (permiso == element) {
                    indice = this.listaPermisosDisponibles.indexOf(element);
                }
            });
            if (!existe) {
                if (indice > -1) {
                    this.listaPermisosDisponibles.splice(indice, 1);
                }
                this.usuarioSecundarioEditar.permisos.push(permiso);
                this.toasterService.pop('Success', 'Permiso agregado', 'El permiso ha sido agregado exitosamente');
            }
        }
    }

    removerPermiso(permiso) {
        this.usuarioSecundarioEditar.permisos.forEach(elemento => {
            if (permiso == elemento) {
                var index = this.usuarioSecundarioEditar.permisos.indexOf(elemento);
                if (index > -1) {
                    this.usuarioSecundarioEditar.permisos.splice(index, 1);
                    this.listaPermisosDisponibles.push(permiso);
                    this.toasterService.pop('Success', 'Permiso removido', 'El permiso ha sido removido exitosamente');
                }
            }
        });
    }

    generarListaPermisosDisponibles() {
        var existe;
        for (var x = 0; x < this.listaPermisos.length; x++) {
            existe = false;
            var permiso = this.listaPermisos[x];
            switch (permiso) {
                case 'ENVIO_DOCUMENTOS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ENVIO_DOCUMENTOS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ENVIO_DOCUMENTOS');
                    }
                }
                    break;
                case 'LECTURA_DOCUMENTOS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_DOCUMENTOS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_DOCUMENTOS');
                    }
                }
                    break;
                case 'ESCRITURA_DOCUMENTOS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ESCRITURA_DOCUMENTOS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ESCRITURA_DOCUMENTOS');
                    }
                }
                    break;
                case 'LECTURA_SUCURSALES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_SUCURSALES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_SUCURSALES');
                    }
                }
                    break;
                case 'ESCRITURA_SUCURSALES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ESCRITURA_SUCURSALES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ESCRITURA_SUCURSALES');
                    }
                }
                    break;
                case 'LECTURA_SERVICIOS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_SERVICIOS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_SERVICIOS');
                    }
                }
                    break;
                case 'ESCRITURA_SERVICIOS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ESCRITURA_SERVICIOS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ESCRITURA_SERVICIOS');
                    }
                }
                    break;
                case 'LECTURA_CLIENTES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_CLIENTES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_CLIENTES');
                    }
                }
                    break;
                case 'ESCRITURA_CLIENTES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ESCRITURA_CLIENTES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ESCRITURA_CLIENTES');
                    }
                }
                    break;
                case 'LECTURA_ESTADISTICAS': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_ESTADISTICAS') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_ESTADISTICAS');
                    }
                }
                    break;
                case 'ENVIO_MENSAJES_RECEPTOR': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ENVIO_MENSAJES_RECEPTOR') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ENVIO_MENSAJES_RECEPTOR');
                    }
                    break;
                }
                case 'LECTURA_COMPROBANTES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_COMPROBANTES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_COMPROBANTES');
                    }
                    break;
                }
                case 'ENVIO_TRANSACCIONES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'ENVIO_TRANSACCIONES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('ENVIO_TRANSACCIONES');
                    }
                    break;
                }
                case 'LECTURA_TRANSACCIONES': {
                    for (var y = 0; y < this.usuarioSecundarioEditar.permisos.length; y++) {
                        if (this.usuarioSecundarioEditar.permisos[y] === 'LECTURA_TRANSACCIONES') {
                            existe = true;
                        }
                    }
                    if (!existe) {
                        this.listaPermisosDisponibles.push('LECTURA_TRANSACCIONES');
                    }
                    break;
                } 
            }//switch
        }
    }

    actualizarUsuarioSecundario() {
        this.loadingData = true;
        delete this.usuarioSecundarioEditar['idUsuarioPrincipal'];
        delete this.usuarioSecundarioEditar['cognitoID'];
        this.usuarioSecundarioService.updateUsuarioSecundario(this.idUsuario, this.usuarioSecundarioEditar).subscribe((data) => {
            this.loadingData = false;
            this.mostrarEdicion = false;
            this.usuarioSecundario = this.usuarioSecundarioEditar;
            this.toasterService.pop('success', 'Actualización exitosa', 'El usuario se ha actualizado exitosamente.');
        }, (error) => {
            if (error.status == 401) {//401
                this.Autorizado = false;
            } else if (error.status == 404) {
                //
            }
            else {
                this.toasterService.pop('error', 'Error al actualizar', 'Hubo un error al actualizar el usuario');
            }
            this.loadingData = false;
        });
    }

    abrirEdicionUsuario() {

    }

    cancelarEdicionUsuario() {
        this.usuarioSecundarioEditar = JSON.parse(JSON.stringify(this.usuarioSecundario));//nueva copia del cliente.
    }

    toggleUtilizarUbicacion() {

    }

    deshabilitarUsuarioSecundario() {
        this.loadingData = true;
        this.estado = this.usuarioSecundarioEditar.estado;
        if (this.estado === 'habilitado') {
            this.usuarioSecundarioService.disableUsuarioSecundario(this.idUsuario, this.usuarioSecundarioEditar).subscribe((data) => {
                this.loadingData = false;
                this.usuarioDeshabilitado = true;

            }, (error) => {
                if (error.status == 400) {//401
                    this.Autorizado = false;
                } else if (error.status == 404) {

                }
                else {
                    this.toasterService.pop('error', 'Error al deshabilitar.', 'La deshabilitación del usuario ha fallado.');
                }
                this.loadingData = false;
            });
        } else {
            if (this.estado === 'deshabilitado') {
                this.toasterService.pop('error', 'Error al deshabilitar.', "El usuario ya se encuentra deshabilitado");
            } else {
                if (this.estado === undefined) {
                    this.toasterService.pop('error', 'Error al deshabilitar', 'El estado del usuario es indefinido');
                }
            }
        }
        this.loadingData = false;
    }

    habilitarUsuarioSecundario() {
        this.loadingData = true;
        this.estado = this.usuarioSecundarioEditar.estado;
        if (this.estado === 'deshabilitado') {
            this.usuarioSecundarioService.enableUsuarioSecundario(this.idUsuario, this.usuarioSecundarioEditar).subscribe((data) => {
                this.loadingData = false;
                this.usuarioHabilitado = true;
            }, (error) => {
                if (error.status == 400) {
                    this.Autorizado = false;
                } else if (error.status == 404) {

                } else {
                    this.toasterService.pop('error', 'Error al habilitar', 'La habilitación del usuario ha fallado');
                }
                this.loadingData = false;
            });
        } else {
            if (this.estado === 'habilitado') {
                this.toasterService.pop('error', 'Error al habilitar', 'El usuario ya se encuentra habilitado');
            } else {
                if (this.estado === undefined) {
                    this.toasterService.pop('error', 'Error al habilitar', 'El estado del usuario es indefinido');
                }
            }
        }
        this.loadingData = false;
    }

    reiniciarClaveUsuarioSecundario() {
        this.loadingData = true;
        this.nombreUsuario = this.usuarioSecundarioEditar.nombreUsuario;
        this.estado = this.usuarioSecundarioEditar.estado;
        if (this.estado === 'habilitado') {
            this.usuarioSecundarioService.resetPasswordUsuarioSecundario(this.usuarioSecundarioEditar).subscribe((data) => {
                this.loadingData = false;
                this.claveReiniciada = true;
            }, (error) => {
                if (error.status == 400) {
                    this.Autorizado = false
                } else if (error.status == 404) {

                } else {
                    this.toasterService.pop('Error', 'Error al reiniciar la contraseña', 'El reinicio de contraseña ha fallado');
                }
                this.loadingData = false;
            });
        } else if (this.estado === 'deshabilitado') {
            this.toasterService.pop('Error', 'Error al reiniciar la contraseña', 'El usuario se encuentra deshabilitado');
        } else if (this.estado === undefined) {
            this.toasterService.pop('Error', 'Error al reiniciar la contraseña', 'El estado del usuario es indefinido');
        }
    }

    agregarConfConsecutivo(){
        this.consecutivo = { numSucursal: this.numSucursal, numTerminal: this.numTerminal };
        this.toasterService.pop("Aviso", "Se ha editado la configuración de consecutivo correctamente");
    }

}
