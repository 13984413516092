import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs/Observable";
import {UserProfile} from "../model";
import {UserLoginService} from "../service";
import {environment} from "../../environments/environment";

@Injectable()
export class UserGuard implements CanActivate, CanActivateChild {


  constructor(public router: Router, public profile: UserProfile,
              public userLoginService: UserLoginService) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable(subscriber => {
      // console.log("CanActivateAdmin", state)
      this.userLoginService.isAuthenticated().subscribe(value => {
        console.log("AuthGuardAdminChild, value", value, environment.userPoolId_subusers);
        let valid = value.valid && value.pool == environment.userPoolId_subusers;
        console.log("AuthGuardAdminChild, valid", valid);
        if (!valid) {
          this.router.navigate(["/checkauth/"]);
        }
        subscriber.next(valid);
        subscriber.complete();
      });
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivateChild(route, state);
  }
}
