import {Pipe, PipeTransform} from '@angular/core';
import {ReceiptTypesList} from "../catalogs/receiptsTypes";

@Pipe({
  name: 'receiptType'
})

export class receiptTypes implements PipeTransform {
  transform(value: number, ...args: any[]): any {
       let result = ReceiptTypesList.tiposDocumento.find(tipo => value==tipo.value).label;
       return result?result:"Documento";
  }
}
