import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {GetQueryReceiptsData, PeticionEnvioTransaccion} from '../model/index';
import {environment} from "../../environments/environment";
import {PeticionMensajeReceptorModel} from "../model/mensajes-receptor/peticion-mensaje-receptor";

@Injectable()
export class ReceiptService {
  private api_url = environment.api_url;
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.headers.append("Content-Type", "application/json");
  }


  /* Imbocar al lambda evexQueryComprobantes */
  evexQueryComprobantes(receipt: GetQueryReceiptsData, accion: string) {
    var options = new HttpParams().set('accion', accion);
    //   alert("ultimo_regis: "+receipt.ultimoRegistroFecha+" ultregis cn: "+receipt.ultimoRegistroCN+" emisor: "+receipt.idEmisor+" int_tipo "+receipt.indTipo+" ind estado"+receipt.indEstado);

    if (receipt.ultimoRegistroFecha != 0) {
      options = options.append('pagina-ultima-fecha', receipt.ultimoRegistroFecha + "");
    }
    if (receipt.ultimoRegistroCN != "") {
      options = options.append('pagina-ultimo-registro', receipt.ultimoRegistroCN + "");
    }
    if (receipt.emisor != "") {
      options = options.append('emisor', receipt.emisor + "");
    }
    if (receipt.receptor != "") {
      options = options.append('receptor', receipt.receptor + "");
    }
    if (receipt.indTipo != 0) {
      options = options.append('ind-tipo', receipt.indTipo + "");
    }
    if (receipt.indEstado != 0) {
      options = options.append('ind-estado', receipt.indEstado + "");
    }
    if (receipt.claveNumerica != "") {
      options = options.append('claveNumerica', receipt.claveNumerica);
    }

    return this.http.get(this.api_url + "/comprobante", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  sendTransaction(sendRequest: PeticionEnvioTransaccion) {
    console.log(JSON.stringify(sendRequest));

    let peticion = {
      archivoPDF: sendRequest.archivoPDF,
      comprobanteXML: sendRequest.comprobanteXML,
      mhRespuestaXML: sendRequest.mhRespuestaXML,
      indEnvio: sendRequest.indEnvio
    };

    if (!sendRequest.archivoPDF || sendRequest.archivoPDF == "") {
      peticion['archivoPDF'] = null;
    }


    if (sendRequest.correoNotificacion != "") {
      peticion['correoNotificacion'] = sendRequest.correoNotificacion;
    }

    return this.http.post(this.api_url + "/transaccion", peticion, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  selfUpload(sendRequest: PeticionEnvioTransaccion) {
    let peticion = {
      archivoPDF: sendRequest.archivoPDF,
      comprobanteXML: sendRequest.comprobanteXML,
      mhRespuestaXML: sendRequest.mhRespuestaXML,
      indEnvio: 1
    };
    if (sendRequest.archivoPDF == "" || !sendRequest.archivoPDF) {
      peticion['archivoPDF'] = null;
    }


    if (sendRequest.correoNotificacion != "") {
      peticion['correoNotificacion'] = sendRequest.correoNotificacion;
    }
    return this.http.post(this.api_url + "/transaccion", peticion, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );
  }

  evexGetComprobante(id: string, accion: string) {
    var options = new HttpParams().set('accion', accion);
    return this.http.get(this.api_url + "/comprobante/" + id, {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  evexAprobarRechazar(entryData: PeticionMensajeReceptorModel) {

    if (!entryData.emailContactoEmisor || entryData.emailContactoEmisor == "") {
      delete entryData.emailContactoEmisor;
    }
    return this.http.put(this.api_url + "/comprobante/" + entryData.claveNumerica + "/respuesta", entryData, {headers: this.headers})
      .pipe(catchError(this.handleError)
      );

  }

  getArchivoUrl(idTransaccion: string, claveNumerica: string, tipoArchivo: string) {
    var options = new HttpParams().set('idTransaccion', idTransaccion).set('claveNumerica', claveNumerica).set('tipoArchivo', tipoArchivo);
    return this.http.get(this.api_url + "/comprobante/" + claveNumerica + "/archivo", {headers: this.headers, params: options});
  }

  private handleError(error: any) {
    return new ErrorObservable(error);
  };

  public queryReceiptsByRDS(numeroPagina: number, busqueda: any) {
    var params = new HttpParams().set('num-pagina', '' + numeroPagina);
    for (var key in busqueda) {
      params = params.set(key, busqueda[key]);
    }
    return this.http.get<any[]>(`${this.api_url}/comprobante/`, {params});
  }

  genPDF(claveNumerica: string, accion: number) {
    var options = new HttpParams().set('accion', accion.toString());
    return this.http.get(this.api_url + "/comprobante/" + claveNumerica + "/gen-pdf", {headers: this.headers, params: options});
  }

  evexGetHtml(id: string, accion: string){
    var options = new HttpParams().set('accion',accion);
    return this.http.get(this.api_url + "/comprobante/" + id + "/html", {headers: this.headers, params: options});
  }
}
