import {Pipe, PipeTransform} from '@angular/core';

//https://www.fileformat.info/info/unicode/category/Sc/list.htm
@Pipe({
  name: 'idTypes'
})

export class identificationTypes implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    switch (value) {
      case 1: {
        return "CEDULA FÍSICA"
      }
      case 2: {
        return "CEDULA JURÍDICA";//
      }
      case 3: {
        return "DIMEX";//
      }
      case 4: {
        return "NITE";//
      }
    }
    return "# ";
  }
}
