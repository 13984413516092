import {Injectable} from "@angular/core";
import {UserLoginService} from "./user-login.service";
import {environment} from "../../environments/environment";

class Config {
  public indTipo: string;

}

/**
 * Servicio encargado de obtener la configuracion
 */
@Injectable()
export class ConfigProvider {

  private config: Config;

  constructor(private userLoginService: UserLoginService) {

  }

  getConfig() {
    return this.config;
  }

  public setConfigAsAdmin() {
    this.setConfig({indTipo: "admin"});
    this.userLoginService.setConfig(environment.userPoolId, environment.clientId);

  }

  public setConfigAsUser() {
    this.setConfig({indTipo: "user"});
    this.userLoginService.setConfig(environment.userPoolId_subusers, environment.clientId_subusers);
  }

  /**
   * Obtiene el listado de cuentas principales que el usuario ha digitado, esto para popular el autocompletado en el login
   */
  getMainUserAccounts() {
    return JSON.parse(localStorage.getItem("session-history"));
  }

  /**
   * Agrega una cuenta a las cuentas principales
   */
  addMainUserAccount(elemento: string) {
    let tmp: any[] = this.getMainUserAccounts();
    tmp.push(elemento);
    localStorage.setItem("session-config-type", JSON.stringify(tmp));
  }

  private setConfig(config: Config) {
    this.config = config;

  }

}
