import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- #1 import module
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalModule, PaginationModule} from 'ngx-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {GeneralModule} from '../general.module';

import {UsuarioSecundarioRoutingModule} from './usuarioSecundario-routing.module';
import {UsuarioSecundarioComponent} from '../../components/usuarioSecundario/usuarioSecundario.component';
import {UsuarioListaComponent} from '../../components/usuarioSecundario/usuario-lista/usuario-lista.component';
import {UsuarioNuevoComponent} from '../../components/usuarioSecundario/usuario-nuevo/usuario-nuevo.component';
import {UsuarioDetalleComponent} from '../../components/usuarioSecundario/usuario-detalle/usuario-detalle.component';

@NgModule({
    imports: [
        UsuarioSecundarioRoutingModule,
        CommonModule,
        GeneralModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        PaginationModule,
        //NgbModule,
        NgxPaginationModule
    ],
    declarations: [UsuarioSecundarioComponent, UsuarioListaComponent, UsuarioDetalleComponent, UsuarioNuevoComponent]
})
export class UsuarioSecundarioModule {
}