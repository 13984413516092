import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app_usuarioSecundario',
    templateUrl: './usuarioSecundario.component.html',
    //styleUrls: ['./profile.component.css'],
    providers: []
})

export class UsuarioSecundarioComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }
}
