export class Permisos{

    public static permisos = [
        "ENVIO_DOCUMENTOS",
        "LECTURA_DOCUMENTOS",
        "ESCRITURA_DOCUMENTOS",
        "LECTURA_SUCURSALES",
        "ESCRITURA_SUCURSALES",
        "LECTURA_SERVICIOS",
        "ESCRITURA_SERVICIOS",
        "LECTURA_CLIENTES",
        "ESCRITURA_CLIENTES",
        "LECTURA_ESTADISTICAS",
        "ENVIO_MENSAJES_RECEPTOR",
        "LECTURA_COMPROBANTES",
        "ENVIO_TRANSACCIONES",
        "LECTURA_TRANSACCIONES"
    ];
    constructor(){}
}
