//Esta estructura contiene solo los elementos requeridos como indispensables
export class PeticionPutPerfilCuenta {
  public nombreCompleto: string;
  public telefono: string;
  public razonSocial: string;
  public codigoTelefonico: number;
  public correoElectronico: string;
  public provincia: number;
  public canton: number;
  public distrito: number;

  constructor() {
  }
}

//    Elementos opcioinales.
//    "direccion" : "foo",          
//    "dataFotoPerfil" : "foo"
