export class UserUpdateProfile {

  public tipoCuenta: number;    // Nombre del usuario (80)
  public esPremium: boolean;
  public razonSocial: string;
  public identificacion: string;    // Identificación (12)
  public indIdentificacion: number;
  /* Indicador para el tipo de identificación Cédula Física=01, Cédula Jurídica=02, DIMEX=03, NITE=04*/
  public nombreCompleto: string;
  public telefono: string;
  public codigoTelefonico: string;
  public correoElectronico: string; // Correo del usuario (60)
  public provincia: number; // Provincia, opc (1)
  public canton: number;    // Cantón, opc (2)
  public distrito: number;  // Distrito, opc (2)
  public direccion: string; // Representa a otras señas, opc (160)
  public urlFotoPerfil: string;

  constructor() {
  }

}


