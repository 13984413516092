import {AbstractControl, ValidatorFn} from '@angular/forms';

export function numeroTerminalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

        var min = false;
        var max = false;

        if (control.value != null && control.value != undefined) {
            max = control.value > 99999;
            min = control.value < 1;
        }


        if (!min && !max) {
            return null;
        } else {
            var err = {};
            if (min) {
                err['min'] = true;
            }
            if (max) {
                err['max'] = true;
            }

            return err;
        }


    };
}
