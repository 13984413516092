import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {ModalModule, PaginationModule} from 'ngx-bootstrap';
import {GlobalUser} from './service/global-user.service';
import {PersistenceService} from './service/persistence.service'
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {CognitoUtil, UserLoginService} from './service';
import {UserProfile} from './model';
import {FormsModule} from "@angular/forms";
import {ProfileService} from './service/profile.service';
import {AuthModule} from './module/auth/auth.module';
import {ErrorHandlerService} from './service/error-handler.service';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {ProfileModule} from './module/profile/profile.module';
import {TokenInterceptor} from './service/token.interceptor';
import {AuthLayoutComponent} from './containers/auth-layout/auth-layout.component';
import {UserComponent} from "./containers/user-layout/user.component";
import {AdminComponent} from "./containers/admin-layout/admin.component";
import {BusquedasConfiguracion } from "./service/busquedas.service";
import {UsuarioSecundarioModule} from './module/usuarioSecundario/usuarioSecundario.module';
// Import containers
import {AdminLayoutComponent, UserLayoutComponent} from './containers';
// Import directives
import {AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES, UsernameValidator} from './directives';
// Import components
import {
  APP_SIDEBAR_NAV,
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
} from './components';
import {AdminAuthGuard} from "./guards/admin-auth.guard";
import {UserAuthGuard} from "./guards/user-auth.guard";
import {AdminGuard} from "./guards/admin.guard";
import {UserGuard} from "./guards/user.guard";
// Import routing module
import {AppRoutingModule} from './app.routing';
import {ConfigProvider} from "./service/config-provider.service";
import {CheckAuthComponent} from "./components/auth/check-auth.component";

const APP_CONTAINERS = [
  AdminLayoutComponent,
  UserLayoutComponent
];


const APP_COMPONENTS = [
  AdminComponent,
  UserComponent,
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
];
const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
  UsernameValidator
];


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    TabsModule.forRoot(),
    AuthModule,
    ProfileModule,
    ToasterModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    UsuarioSecundarioModule,
  ],
  declarations: [
    AdminLayoutComponent,
    AuthLayoutComponent,
    UserLayoutComponent,
    AppComponent,
    CheckAuthComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES
  ],
  providers: [UserProfile, GlobalUser, PersistenceService, AdminAuthGuard, UserAuthGuard, AdminGuard, BusquedasConfiguracion,UserGuard,ConfigProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    UserLoginService, CognitoUtil, ProfileService, ErrorHandlerService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
