import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service/user-login.service";
import {ConfigProvider} from "../../service/config-provider.service";
import {ToasterService} from "angular2-toaster";
import {UserRegistrationService} from "../../service";

@Component({
  selector: 'forgot-password-step-2',
  templateUrl: 'forgot-password-step-2.component.html',
  styleUrls: ['./forgot-password-step-2.component.css'],
  providers: [UserRegistrationService]
})

export class ForgotPasswordStep2Component implements OnInit {
  public verificationCode: string;
  public email: string;
  public password: string;
  public errorMessage: string;
  public loading = false;
  public accType: string = null;
  private sub: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserLoginService,
              private configProvider: ConfigProvider,
              private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.accType = this.configProvider.getConfig().indTipo;
    this.sub = this.route.params.subscribe(params => {
      this.email = params['email'];
    });
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onNext() {
    this.errorMessage = null;
    this.userService.confirmNewPassword(this.email, this.verificationCode, this.password, this);
  }

  cognitoCallback(message: string) {
    if (message != null) { //error
      this.errorMessage = message;
      console.log("result: " + this.errorMessage);
    } else { //success
      this.toasterService.pop("success","Cambiado","La contraseña ha sido cambiada")
      this.router.navigate(['../../login'], {relativeTo: this.route});
    }
  }

}
