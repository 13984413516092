//Lista de los tipos de identificación descritos por el MH
export class IdentificationList {

    public static IdType = [
        {
            "label": "Cédula Física",
            "value": 1,
            "format": "X-XXXX-XXXX", 
            "mask" : [/[1-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        },
        {
            "label": "Cédula Jurídica",
            "value": 2,
            "format": "X-XXX-XXXXXX",
            "mask" : [/[3-4]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        },
        {
            "label": "DIMEX",
            "value": 3,
            "format": "XXXXXXXXXXXX /11 o 12 dígitos",
            "mask" : [ /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        },
        {
            "label": "NITE",
            "value": 4,
            "format": "XXXXXXXXXX",
            "mask" : [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/]
        }
    ];

}
