import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- #1 import module
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from '../../components/profile/profile.component';
import {UserDataComponent} from '../../components/profile/user-data/user.component';
import {ATVComponent} from '../../components/profile/atv/atv.component'
import {ModalModule, TabsModule} from 'ngx-bootstrap';
import {UserEditComponent} from '../../components/profile/user-edit/user-edit.component';
import {GeneralModule} from '../general.module';
import {ProfileMenuComponent} from '../../profile/profile-menu.component';
import {NgxToggleModule} from "ngx-toggle";
import {ConfigComponent} from '../../components/profile/config/config.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    TabsModule,
    GeneralModule,
    NgxToggleModule
  ],
  declarations: [ ATVComponent, ProfileComponent, ProfileMenuComponent, UserDataComponent, UserEditComponent, ConfigComponent]
})
export class ProfileModule {
}
