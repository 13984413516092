import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {numeroTerminalValidator} from './numero-terminal.validator';

@Directive({
    selector: '[numeroTerminal][ngModel]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumeroTerminalDirective, multi: true}]
})
export class NumeroTerminalDirective implements Validator {
    private validator = numeroTerminalValidator();

    validate(control: AbstractControl): { [key: string]: any } {
        return this.validator(control);
    }

    constructor() {
    }

}
