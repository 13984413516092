//Modelo requerido para registrar un usuario
export class UsuarioSecundarioRequest {

    nombreUsuario: string;
    nombreUsuarioSecundario: string;
    correo: string;
    clave: string;
    idUsuario: string;

    constructor() {
    }
}