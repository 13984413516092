import {Inject, Injectable} from "@angular/core";
import {CognitoCallback, CognitoUtil} from "./cognito.service";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import {RegistrationUser} from "../model/registration-user";
import {NewPasswordUser} from "../model/new-password-user";
import {RegistrationUserCustomer} from '../model/registration-user-customer';
import {environment} from "../../environments/environment";
import {RegistrationUserBusiness} from '../model/registration-user-business';
import {HttpClient, HttpHeaders} from '@angular/common/http';

/**
 * Servicio encargado de realizar mantenmientos de usuarios
 */
@Injectable()
export class UserRegistrationService {
  private api_url = environment.api_url;
  private headers: HttpHeaders = new HttpHeaders();

  constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil, private http: HttpClient) {
    this.headers.append("Content-Type", "application/json");
  }

  /**
   * Determina si el nombre de usuario esta en uso
   * @param user usuario que se desea registrar
   * @param callback funcion que se invoca cuando responde el cognito
   */
  checkUsernameAvailability(user: string) {
    this.headers.set("content-type", "application/json");
    return this.http.post(this.api_url + "/registro/checkusername", user, {headers: this.headers});
  }


  /**
   * Registra un usuario en la plataforma
   * @param user usuario que se desea registrar
   * @param callback funcion que se invoca cuando responde el cognito
   */
  register(user: RegistrationUser) {
    this.headers.set("content-type", "application/json");
    if (user instanceof RegistrationUserCustomer) {
      return this.http.post(this.api_url + "/registro/customer", user, {headers: this.headers});
    } else if (user instanceof RegistrationUserBusiness) {
      return this.http.post(this.api_url + "/registro/business", user, {headers: this.headers});
    }
  }

  /**
   *  Confirma el email del usuario y completa la creacion de la cuenta
   * @param username nombre de usuario para el cual se quiere confirmar el codigo
   * @param confirmationCode codigo de congirmación enviado al email
   * @param callback funcion que se invocará cuando responda cognito
   */
  confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  /**
   * Re envio del codigo de verificacion del email
   * @param username nombre de usuario de cognito
   * @param callback funcion que se invocara cuando responda cognito
   */
  resendCode(username: string, callback: CognitoCallback): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  /**
   * Le cambia la clave al usuario
   * @param newPasswordUser usuario al cual se cambiaran los credenciales
   * @param callback funcion que se invoca cuando cognito responda
   */
  newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
    console.log(newPasswordUser);
    // Get these details and call
    //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    const authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
          onSuccess: function (result) {
            callback.cognitoCallback(null, userAttributes);
          },
          onFailure: function (err) {
            callback.cognitoCallback(err, null);
          }
        });
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err, null);
      }
    });
  }
}
