import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  AboutComponent,
  ConfirmRegistrationComponent,
  ForgotPasswordComponent,
  ForgotPasswordStep2Component,
  LoginComponent,
  NewPasswordComponent,
  RegisterComponent,
  ResendComponent
} from "../../components/auth/index";

const routes: Routes = [
  {path: 'about', component: AboutComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'confirmRegistration/:username', component: ConfirmRegistrationComponent},
  {path: 'resendCode', component: ResendComponent},
  {path: 'forgotPassword/:email', component: ForgotPasswordStep2Component},
  {path: 'forgotPassword', component: ForgotPasswordComponent},
  {path: 'newPassword/:email', component: NewPasswordComponent}, //<- Guard solo si el usuario esta reistrado
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
