import {Injectable} from '@angular/core';
import {RegistrationUser} from './registration-user';

@Injectable()
export class RegistrationUserBusiness extends RegistrationUser {
  public llaveCriptografica;
  public pinLlaveCriptografica;
  public usuarioATV;
  public passAtv;
}
