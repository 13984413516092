import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {userPasswordValidator} from './user-password.validator';

@Directive({
  selector: '[user-password-rgx][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: UserPasswordDirective, multi: true}]
})

export class UserPasswordDirective implements Validator {
  private validator = userPasswordValidator();

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }
}


    
    

   