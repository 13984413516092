import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'estadoTransaccion'
})

export class EstadoTransaccionPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    switch (value) {
      case 0: {
        return "En cola";
      }
      case 1: {
        return "Trabajando";
      }
      case 2: {
        return "Entregado";
      }
      case 3: {
        return "Rechazado";
      }
      case 4: {
        return "Error";
      }
      case 5: {
        return "Esperando al usuario";
      }
    }
  }
}

/**
 *      QUEUED(0),
 WORKING(1),
 SUCCESSFUL(2),
 REJECTED(3),
 ERROR(4),
 WAITING_FOR_USER(5)
 */
