import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable()
export class TransactionService {

  private api_url = environment.api_url + "/transaccion";
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.headers.append("Content-Type", "application/json");
  }

  queryTransactions(transactionRequest: any) {
    console.log(transactionRequest);
    let options = new HttpParams().set("accion", transactionRequest.accion);
    if (transactionRequest.ultimoRegistroFecha != 0) {
      options = options.set('pagina-ultima-fecha', transactionRequest.ultimoRegistroFecha + "");
    }
    if (transactionRequest.ultimoRegistroID != "") {
      options = options.set('pagina-ultimo-registro', transactionRequest.ultimoRegistroID + "");
    }
    if (transactionRequest.accion != "") {
      options = options.set('accion', transactionRequest.accion + "");
    }
    if (transactionRequest.indEstado >= 0) {
      options = options.set('ind-estado', transactionRequest.indEstado + "");
    }
    return this.http.get(this.api_url, {headers: this.headers, params: options}).pipe(catchError(this.handleError));
  }

  getTransaction(id: string, accion: string): any {
    let options = new HttpParams().set('accion', accion).set("id", id);
    return this.http.get(this.api_url + '/' + id, {headers: this.headers, params: options}).pipe(catchError(this.handleError));
  }

  overwriteTransaction(id: string, accion: boolean) {
    let params = {};
    params["sobreescribir"] = accion;
    return this.http.put(this.api_url + '/' + id + "/confirmar", params, {headers: this.headers}).pipe(catchError(this.handleError));
  }

  getArchivoUrl(idTransaccion: string, accion: string, tipoArchivo: string) {
    var options = new HttpParams().set('accion', accion).set('tipoArchivo', tipoArchivo);
    return this.http.get(this.api_url + '/' + idTransaccion + "/archivo", {headers: this.headers, params: options});
  }

  private handleError(error: any) {
    return new ErrorObservable(error);
  };


  public queryTransacctionByRDS(numeroPagina: number, busqueda: any) {
    var params = new HttpParams().set('num-pagina', '' + numeroPagina);
    for (var key in busqueda) {
      params = params.set(key, busqueda[key]);
    }
    return this.http.get<any[]>(`${this.api_url}`, {params});
  }


}
