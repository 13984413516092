import {NgModule,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomInputFileComponent} from '../components/custom-components/inputFile/cInputFile.component';
import {LoadingGif} from '../components/custom-components/loadingCircle/loadingcircle.component';
import {identificationTypes} from '../pipe/indentificationTypes.pipe';
import {EstadoTransaccionPipe} from '../pipe/estadoTransaccion.pipe';
import {ToasterModule} from 'angular2-toaster';
import {receiptTypes} from '../pipe/receiptTypes.pipe';
import {FormsModule} from '@angular/forms';
import {GifCargaComponent} from '../components/custom-components/gif-carga/gif-carga.component'
import {PermisosPipe} from '../pipe/permisos.pipe'
import { UserPasswordDirective } from '../directives/validators/user-password/user-password.directive';
import { NumeroSucursalDirective } from '../directives/validators/sucursal/numeroSucursal/numero-sucursal.directive';
import { NumeroTerminalDirective } from '../directives/validators/sucursal/numeroTerminal/numero-terminal.directive';


@NgModule({
  imports: [
    CommonModule,
    ToasterModule,
    FormsModule
  ],
  exports: [
    CustomInputFileComponent,
    LoadingGif,
    identificationTypes,
    receiptTypes,
    ToasterModule,
    EstadoTransaccionPipe,
    GifCargaComponent,
    UserPasswordDirective,
    PermisosPipe,
    NumeroSucursalDirective,
    NumeroTerminalDirective
    
  ],
  declarations: [UserPasswordDirective,GifCargaComponent, CustomInputFileComponent, LoadingGif, identificationTypes, receiptTypes, EstadoTransaccionPipe,PermisosPipe, NumeroSucursalDirective, NumeroTerminalDirective],
  providers: [],
})
export class GeneralModule {
}
