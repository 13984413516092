export class UserProfileData {

  public tipoCuenta: number;    // Nombre del usuario (80)
  public esPremium: boolean;
  public razonSocial: string;
  public identificacion: string;    // Identificación (12)
  public indIdentificacion: number;
  /* Indicador para el tipo de identificación Cédula Física=01, Cédula Jurídica=02, DIMEX=03, NITE=04*/
  public nombreCompleto: string;
  public telefono: string;
  public codigoTelefonico: number;
  public correoElectronico: string; // Correo del usuario (60)
  public provincia: number; // Provincia, opc (1)
  public canton: number;    // Cantón, opc (2)
  public distrito: number;  // Distrito, opc (2)
  public direccion: string; // Representa a otras señas, opc (160)
  public urlFotoPerfil: string;
  public nombreUsuario: string;
  public llaveCriptografica: string;
  public confRecepcionNotificaciones: number;

  constructor() {

    this.tipoCuenta = 0;
    this.esPremium = false;
    this.razonSocial = "";
    this.identificacion = "";
    this.indIdentificacion = 0;
    this.nombreCompleto = "";
    this.telefono = "";
    this.codigoTelefonico = 506,
    this.correoElectronico = "";
    this.provincia = 1;
    this.canton = 1;
    this.distrito = 1;
    this.direccion = "";
    this.urlFotoPerfil = "";
    this.nombreUsuario = "";
    this.llaveCriptografica = "";
    this.confRecepcionNotificaciones = 0;



  }

}
