import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'permisosPipe'
})

export class PermisosPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        switch (value) {
            case 'ENVIO_DOCUMENTOS': {
                return "Envio de documentos";
            }
            case 'LECTURA_DOCUMENTOS': {
                return "Lectura de documentos";
            }
            case 'ESCRITURA_DOCUMENTOS': {
                return "Escritura de documentos";
            }
            case 'LECTURA_SUCURSALES': {
                return "Lectura de sucursales";
            }
            case 'ESCRITURA_SUCURSALES': {
                return "Escritura de sucursales";
            }
            case 'LECTURA_SERVICIOS': {
                return "Lectura de servicios";
            }
            case 'ESCRITURA_SERVICIOS': {
                return "Escritura de servicios";
            }
            case 'LECTURA_CLIENTES': {
                return "Lectura de clientes";
            }
            case 'ESCRITURA_CLIENTES': {
                return "Escritura de clientes";
            }
            case 'LECTURA_ESTADISTICAS': {
                return "Lectura de estadisticas";
            }
            case 'ENVIO_MENSAJES_RECEPTOR': {
                return "Envio de mensajes receptor";
            }
            case 'LECTURA_COMPROBANTES': {
                return "Lectura de comprobantes";
            }
            case 'ENVIO_TRANSACCIONES': {
                return "Envio de transacciones";
            }
            case 'LECTURA_TRANSACCIONES': {
                return "Lectura de transacciones";
            }
        }
        return "";
    }
}
