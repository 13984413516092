/**
 *  Archivo de Environment que contiene la config del sistema para un ambiente determinado
 *  En este caso para el ambiente de desarrollo
 *
 production: indica si la config es de un ambiente de produccion
 region: la region de aws

 identityPoolId: el id del pool de cognito
 userPoolId: el id del user pool de cognito
 clientId: el client id de cognito

 rekognitionBucket: bucket de rekognition
 albumName: album para rekognition
 bucketRegion: region del bucket de rekognition

 ddbTableName: tabla del dynamo db para guardar logs

 cognito_idp_endpoint:
 sts_endpoint:
 cognito_identity_endpoint:
 dynamodb_endpoint:
 s3_endpoint:
 */

export const environment = {
  production: false,
  region: 'us-east-1',

  identityPoolId: '',
  userPoolId: 'us-east-1_ZBdscchAh',
  clientId: '5cdr4lhmj88jp4dqgvvddtfsfv',

  userPoolId_subusers: 'us-east-1_T7vxQ2VwQ',
  clientId_subusers: '1tbj8v0cqjrtms83u98n443asp',

  rekognitionBucket: 'rekognition-pics',
  albumName: "usercontent",
  bucketRegion: 'us-east-1',
  ddbTableName: 'LoginTrail',

  //dominio en el cual hacemos deploy (cognito guarda ls cookies con este dominio)
     domain: "sandbox.evex.cr",


  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',

  api_url: 'https://api-sandbox.evex.cr',
  facturador_evex_api_url: 'https://factura-api-sandbox.evex.cr'
};
