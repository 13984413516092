import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UsuarioSecundarioComponent} from '../../components/usuarioSecundario/usuarioSecundario.component';
import {UsuarioListaComponent} from '../../components/usuarioSecundario/usuario-lista/usuario-lista.component';
import {UsuarioNuevoComponent} from '../../components/usuarioSecundario/usuario-nuevo/usuario-nuevo.component';
import {UsuarioDetalleComponent} from '../../components/usuarioSecundario/usuario-detalle/usuario-detalle.component';

const routes: Routes = [{
    path: '', data: {title: 'Usuarios'}, component: UsuarioSecundarioComponent, children: [
        {path: '', redirectTo: 'lista', pathMatch: 'full'},
        {path: 'lista', data: {title: 'Listado de Usuarios'}, component: UsuarioListaComponent},
        {path: 'nuevo', data: {title: 'Nuevo Usuario'}, component: UsuarioNuevoComponent},
        {path: 'detalle/:id', data: {title: 'Detalle de usuario'}, component: UsuarioDetalleComponent},

    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsuarioSecundarioRoutingModule {
}
