export class RespuestaComprobantes {
  public claveNumerica: string;
  public nombreEmisor: string;
  public nombreReceptor: string;
  public totalComprobanet: number;
  public tipoComprobante: number;
  public fechaEntregaEVEx: number;
  public fechaEmisionMH: number;
  public tieneRespuesta: boolean;

  constructor() {
  }
}
