import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {UserLoginService} from "../service/user-login.service";
import {CognitoUtil} from "../service/cognito.service";
import {Router} from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public userService: UserLoginService, public cognito: CognitoUtil, private router:Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let sub = this.cognito.getIdToken().subscribe((tkn) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${tkn}`
          }
        });
      }, (err) => {
        this.router.navigate(["/checkauth"])
        console.log(err);
      },
      () => {
        sub.unsubscribe();
      }
    );
    return next.handle(request);
  }
}
