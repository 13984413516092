import {Injectable} from '@angular/core';

@Injectable()

//Modelo registro usuario
export abstract class RegistrationUser {
  contrasena: string; // Contraseña de registro
  correoElectronico: string;
  nombreUsuario: string;
  nombreCompleto: string;
  indIdentificacion: string;
  identificacion: string;

  constructor() {
  }
}
