import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {environment} from "../../environments/environment";


@Injectable()
export class DashboardService {
  private api_url = environment.api_url;
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.headers.append("Content-Type", "application/json");
  }

  //modo 0 metaFecha 0

  evexGetDashboard(id: string, accion: string) {
    var options = new HttpParams().set('accion', accion);
    return this.http.get(this.api_url + "/dashboard", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  //GLOBAL, ANUAL, MENSUAL, DIARIO

  evexGetDashboard_General() {
    var options = new HttpParams().set('modo', 'GLOBAL');
    return this.http.get(this.api_url + "/dashboard", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }


  evexGetDashboard_Today(day: string, month: string, year: string) {
    var metaFecha = day + month + year;
    var options = new HttpParams().set('modo', '3').set('metaFecha', metaFecha);
    return this.http.get(this.api_url + "/dashboard", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }


  evexGetDashboard_MonthDays(day: string, month: string, year: string) {
    var metaFecha = "01" + month + year;
    var options = new HttpParams().set('modo', '3').set('metaFecha', metaFecha);
    return this.http.get(this.api_url + "/dashboard", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  evexGetDashboard_12Month(modo: string, annioDesde: number, mesDesde: number, diaDesde: number, annioHasta: number, mesHasta: number, diaHasta: number) {

    var options = new HttpParams().set('annioDesde', annioDesde.toString())
      .set('annioHasta', annioHasta.toString())
      .set('mesDesde', mesDesde.toString())
      .set('mesHasta', mesHasta.toString())
      .set('diaDesde', diaDesde.toString())
      .set('diaHasta', diaHasta.toString())
      .set('modo', modo);

    return this.http.get(this.api_url + "/dashboard", {headers: this.headers, params: options})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }


  private handleError(error: any) {
    return new ErrorObservable(error);
  };


}


