export {LoginComponent} from './login.component';
export {NewPasswordComponent} from './new-password.component';
export {ForgotPasswordComponent} from './forgot-password.component';
export {MFAComponent} from './mfa.component';
export {RegisterComponent} from './register.component';
export {AboutComponent} from './about.component';
export {ConfirmRegistrationComponent} from './confirm-registration.component';
export {ResendComponent} from './resend.component';
export {ForgotPasswordStep2Component} from './forgot-password-step-2.component';
export {LogoutComponent} from './logout.component';
