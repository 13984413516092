export const navigation = [
  {
    name: 'Envio de Comprobantes',
    url: 'envio',
    icon: 'icon-paper-plane'
  },
  {
    name: 'Gráficas',
    url: 'dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Comprobantes',
    url: 'log',
    icon: 'icon-doc',
    children: [
      {
        name: 'Recibidos',
        url: 'comprobantes/recibidos',
        icon: 'icon-drawer'
      },
      {
        name: 'Enviados',
        url: 'comprobantes/enviados',
        icon: 'icon-paper-plane'
      }
    ]
  },
  {
    name: 'Transacciones',
    url: 'transaccion',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Entrantes',
        url: 'transaccion/recibido/1',
        icon: 'icon-drawer'
      },
      {
        name: 'Salientes',
        url: 'transaccion/enviado/0',
        icon: 'icon-paper-plane'
      }
    ]
  },
  {
    name: 'Reportes',
    url: 'reportes',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Compras',
        url: 'reportes/compras',
        icon: 'icon-drawer'
      },
      {
        name: 'Ventas',
        url: 'reportes/ventas',
        icon: 'icon-paper-plane'
      },
      {
        name: 'Detalle Compra/Ventas',
        url: 'reportes/compras-ventas',
        icon: 'icon-speedometer'
      }
    ]
  }
];


export const navigationPremium = [
  {
    title: true,
    name: 'Cuenta',
    wrapper: {
      element: 'span'
    },
    class: 'text-center'
  },
  {
    name: 'Envio de Comprobantes',
    url: 'envio',
    icon: 'icon-paper-plane'
  },
  {
    name: 'Gráficas',
    url: 'dashboard',
    icon: 'icon-speedometer'
  },
  {
    title: true,
    name: 'Recepción y envío',
    wrapper: {
      element: 'span'
    },
    class: 'text-center'
  },
  {
    name: 'Comprobantes',
    url: 'log',
    icon: 'icon-doc',
    children: [
      {
        name: 'Recibidos',
        url: 'comprobantes/recibidos',
        icon: 'icon-drawer'
      },
      {
        name: 'Enviados',
        url: 'comprobantes/enviados',
        icon: 'icon-paper-plane'
      }

    ]
  },
  {
    name: 'Transacciones',
    url: 'transaccion',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Entrantes',
        url: 'transaccion/recibido/1',
        icon: 'icon-drawer'
      },
      {
        name: 'Salientes',
        url: 'transaccion/enviado/0',
        icon: 'icon-paper-plane'
      }
    ]
  },
  {
    title: true,
    name: 'Respuesta',
    wrapper: {
      element: 'span'
    },
    class: 'text-center'
  },
  {
    name: 'Mensajes Receptor',
    url: 'mensajesreceptor/enviado',
    icon: 'icon-people'
  },
  {
    name: 'Reportes',
    url: 'reportes',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Compras',
        url: 'reportes/compras',
        icon: 'icon-drawer'
      },
      {
        name: 'Ventas',
        url: 'reportes/ventas',
        icon: 'icon-paper-plane'
      },
      {
        name: 'Detalle Compra/Ventas',
        url: 'reportes/compras-ventas',
        icon: 'icon-speedometer'
      }
    ]
  },

];
