export class RespuestaComercio {

  public informacionPublica: number;
  public nombreComercio: string;
  public razonSocial: string;
  public tiposPago: string;
  public correoElectronico: string;

  public facilidades: string[];
  public lugares: string[];
  public telefonos: string[];

  constructor() {
  }
}




