import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../service/index';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  providers: [ProfileService]
})

export class ProfileComponent implements OnInit {

  public informacionPublica: boolean = false;
  public isPremium: boolean = true;
  private username: string;

  constructor(public profileService: ProfileService) {
  }

  ngOnInit() {
    let sub = this.profileService.getProfile().subscribe(
      (data) => {
        this.isPremium = data.esPremium;
        setTimeout(() => {

        }, 5000);
      },
      (error) => {
        //  this.toasterService.pop('error', 'Error', "No fué posible la solicitud de archivos u información solicitada");
      },
      () => {
        sub.unsubscribe();
      }
    );
  }


}
