
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, retry } from 'rxjs/operators';


//import {  PeticionBusqueda } from '../model/peticion-busqueda';
import {ReceiptRequestRDS} from '../model/receipt/receipt-request-RDS';


@Injectable()
export class BusquedasConfiguracion {
    
    private configuracion;// Map string object
    private listas;// Map string object array


    constructor(private http: HttpClient) {
        this.configuracion = new Map();
        this.listas = new Map();
    }

    getConfig(key:string) {
        if(this.configuracion.has(key)){
            return(this.configuracion.get(key));
        }
        else{
            return null;
        }
    }

    setConfig(key:string , obj: ReceiptRequestRDS) {
        if(this.configuracion.has(key)){
            this.configuracion.delete(key);
        }
        this.configuracion.set(key,obj);
    }
    deleteConfig(key:string){
        if(this.configuracion.has(key)){
            this.configuracion.delete(key);
        }
    }

    getArray(key:string) {
        if(this.listas.has(key)){
            return(this.listas.get(key));
        }
        else{
            return([]);
        }
    }

    setArray(key:string , obj: any[]) {
        if(this.listas.has(key)){
            this.listas.delete(key);
        }
        this.listas.set(key,obj);
    }    

    hasConfig(key:string) {
         return ( this.configuracion.has(key));
    }


   






    
}


