import {Component} from "@angular/core";
import {UserRegistrationService} from "../../service/user-registration.service";
import {CognitoCallback} from "../../service/cognito.service";
import {Router} from "@angular/router";

/**
 * Componente encargado de re enviar el codigo de confirmacion al registrar un usuario en la plataforma
 */
@Component({
  selector: 'resend',
  templateUrl: 'resend.component.html',
  styles: [`.spinner {
    margin: auto;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }`],
  providers: [UserRegistrationService]
})
export class ResendComponent implements CognitoCallback {

  email: string;
  errorMessage: string;
  loading = false;

  constructor(public registrationService: UserRegistrationService, public router: Router) {

  }

  resendCode() {
    this.registrationService.resendCode(this.email, this);
  }

  cognitoCallback(error: any, result: any) {
    if (error != null) {
      this.errorMessage = "Something went wrong...please try again";
    } else {
      this.router.navigate(['/auth/confirmRegistration', this.email]);
    }
  }
}
