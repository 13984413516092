import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {UserRegistrationService} from '../../service/user-registration.service';
import {RegistrationUser} from "../../model/registration-user";
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import emailMask from 'text-mask-addons/dist/emailMask';
import {IdentificationList} from "../../catalogs/indetificationTypes";
import {RegistrationUserCustomer} from '../../model/registration-user-customer';
import {RegistrationUserBusiness} from '../../model/registration-user-business';
import {ToasterService} from 'angular2-toaster';
import {ModalDirective} from 'ngx-bootstrap';

/**
 * Componente encargado de registrar un usuario en la plataforma
 */
@Component({
  selector: 'register',
  templateUrl: 'register.component.html',
  styles: [`
    .spinner {
    margin: auto;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }`],
  providers:[UserRegistrationService]
})

export class RegisterComponent {
  @ViewChild('modalCustomer') modalCustomer: ModalDirective;
  @ViewChild('modalBusiness') modalBusiness: ModalDirective;
  isModalCustomerShown: boolean = false;
  isModalBusinessShown: boolean = false;
  loading = false;

  public file_src_cedfront; // contiene el dataurl para el frente de la id
  public file_src_cedback; // contiene el dataurl para el reverso de la id
  public file_src_selfie; // contiene el dataurl para la imagen de la foto

  public is_valid_cedfront = false; // indicador de validez del archivo
  public is_valid_cedback = false; // indicador de validez del archivo
  public is_valid_selfie = false; // indicador de validez del archivo
  public touched_files_customer = false; // indicador true => fue cargado algun archivo

  public is_valid_cryptokey = false; // indicador de validez de archivo de llave
  public touched_files_business = false; // indicador true => fue cargado algun archivo

  public registrationUser: RegistrationUser;
  public errorMessage: string;
  public selectedAccountType: string = "business";
  public selectedTabIndex: number;
  public idTemporalType: any; // temporal para almacenar el indicador de tipo de id
  public idTemporalValue: any; // temporal para almacenar el valor de id
  //Lista de los tipos de identificación descritos por el MH
  public indentificationType = IdentificationList.IdType;
  public maski; // mascara para validacion de inputs
  public formato; // formato de los numeros de identificación
  public emailmask = emailMask; // mascara para validacion de formato de correos

  @ViewChild("customerRadio") customerRadio: ElementRef;
  @ViewChild("businessRadio") businessRadio: ElementRef;
  @ViewChild('tabs') tabs: TabsetComponent;
  @ViewChild("inputid") inputID: ElementRef;
  @ViewChild("inputidrev") inputIDRev: ElementRef;
  @ViewChild('inputimg') inputImg: ElementRef;
  @ViewChild('inputLlave') inputLlave: ElementRef;

  constructor(public userService: UserRegistrationService, router: Router, private msgService: ToasterService) {
    this.onInit();
  }

  onInit() {
    this.selectedTabIndex = 0;
    this.errorMessage = null;
    this.registrationUser = new RegistrationUserBusiness();
  }

  //selecciona el tipo de cuenta deseado incluso cuando no se selecciona directamente el radio button
  selectAccount(type: string) {
    this.selectedAccountType = type;
    this.touched_files_business = false;
    this.touched_files_customer = false;
    switch (type) {
      case "customer": {
        this.registrationUser = new RegistrationUserCustomer();
        this.customerRadio.nativeElement.dispatchEvent(new MouseEvent('click', {bubbles: false}));
        break;
      }
      case "business": {
        this.registrationUser = new RegistrationUserBusiness();
        this.businessRadio.nativeElement.dispatchEvent(new MouseEvent('click', {bubbles: false}));
        break;
      }
    }
  }

  next(idx) {
    this.selectedTabIndex = idx;
    this.tabs.tabs[idx].active = true;
  }

  prev(idx) {
    this.selectedTabIndex = idx;
    this.tabs.tabs[idx].active = true;
  }

  beforeChange(event) {
    console.log(this.selectedTabIndex);
    this.selectedTabIndex = event;
  }

  registro() {
    this.loading = true;
    this.registrationUser.identificacion = this.idTemporalValue;
    this.registrationUser.indIdentificacion = this.idTemporalType;
    this.userService.register(this.registrationUser).subscribe((resp) => {
        console.log(resp);
        this.msgService.pop("success", "Creada", "Su cuenta ha sido creada");
        switch (this.selectedAccountType) {
          case "customer": {
            this.showModalCustomer();
            break;
          }
          case "business": {
            this.showModalBusiness();
            break;
          }
        }
        this.loading = false;
      },
      (err) => {
        console.log(JSON.stringify(err));
        var mensaje = JSON.parse(err.error.errorMessage);
        this.msgService.pop("error", "Error", mensaje.message);
        this.loading = false;
      });
    console.log("registrando usuario: ", this.registrationUser);
  }

  actualizeMask() {
    // se ha restado 1 debido a que los indices comienzan de cero
    this.maski = IdentificationList.IdType[this.idTemporalType - 1].mask;
    // se ha restado 1 debido a que los indices comienzan de cero
    this.formato = IdentificationList.IdType[this.idTemporalType - 1].format;
    this.idTemporalValue = "";
  }

  showModalBusiness(): void {
    this.isModalBusinessShown = true;
  }

  hideModalBusiness(): void {
    this.modalBusiness.hide();
  }

  showModalCustomer(): void {
    this.isModalCustomerShown = true;
  }

  hideModalCustomer(): void {
    this.modalCustomer.hide();
  }

  // Metodo que sera llamado cada vez que el usaurio seleccione una nueva imagen desde el form
  fileChangeId(input) {
    if (!input.files[0]) {
      this.is_valid_cedfront = false;
    }
    else if (input.files[0].size < 2000000) {
      let img: any;
      img = document.createElement("imgid");
      img.src = window.URL.createObjectURL(input.files[0]);
      let reader = new FileReader(); // Crear un FileReader
      // Agregar un event listener para el cambio
      reader.addEventListener("load", (event) => {
        img.src = event.target["result"]; // Obtener el (base64 de la imagen)
        (<RegistrationUserCustomer>this.registrationUser).fotoIndentificacionDelante = img.src.split(',')[1];
        this.file_src_cedfront = img.src; // Redimensionar la imagen
      }, false);
      reader.readAsDataURL(input.files[0]);
      this.touched_files_customer = true;
      this.is_valid_cedfront = true;
    } else {
      this.is_valid_cedfront = false;
      console.log("tamaño del archivo: ", input.files[0].size);
      this.inputID.nativeElement.value = "";
      this.msgService.pop("error", "Error", "El archivo es demasiado grande");
    }
  }

  // Metodo que sera llamado cada vez que el usaurio seleccione una nueva imagen desde el form
  fileChangeIDRev(input) {
    if (!input.files[0]) {
      this.is_valid_cedback = false;
    }
    else if (input.files[0].size < 2000000) {
      let img: any;
      img = document.createElement("imgidrev");
      img.src = window.URL.createObjectURL(input.files[0]);
      let reader = new FileReader(); // Crear un FileReader
      // Agregar un event listener para el cambio
      reader.addEventListener("load", (event) => {
        img.src = event.target["result"]; // Obtener el (base64 de la imagen)
        (<RegistrationUserCustomer>this.registrationUser).fotoIndentificacionReverso = img.src.split(',')[1];
        this.file_src_cedback = img.src; // Redimensionar la imagen
      }, false);
      reader.readAsDataURL(input.files[0]);
      this.touched_files_customer = true;
      this.is_valid_cedback = true;
    } else {
      this.is_valid_cedback = false;
      console.log("tamaño del archivo: ", input.files[0].size);
      this.inputIDRev.nativeElement.value = "";
      this.msgService.pop("error", "Error", "El archivo es demasiado grande");
    }
  }

  // Metodo que sera llamado cada vez que el usaurio seleccione una nueva imagen desde el form
  fileChangeSelfie(input) {
    if (!input.files[0]) {
      this.is_valid_selfie = false;
    }
    else if (input.files[0].size < 2000000) {
      let img: any;
      img = document.createElement("imgselfie");
      img.src = window.URL.createObjectURL(input.files[0]);
      let reader = new FileReader(); // Crear un FileReader
      // Agregar un event listener para el cambio
      reader.addEventListener("load", (event) => {
        img.src = event.target["result"]; // Obtener el (base64 de la imagen)
        (<RegistrationUserCustomer>this.registrationUser).fotoPerfil = img.src.split(',')[1];
        this.file_src_selfie = img.src; // Redimensionar la imagen
      }, false);
      reader.readAsDataURL(input.files[0]);
      this.touched_files_customer = true;
      this.is_valid_selfie = true;
    } else {
      this.is_valid_selfie = false;
      console.log("tamaño del archivo: ", input.files[0].size);
      this.inputImg.nativeElement.value = "";
      this.msgService.pop("error", "Error", "El archivo es demasiado grande");
    }
  }

  //en caso de cuentas business
  // Metodo que sera llamado cada vez que el usaurio seleccione una nueva imagen desde el form
  fileChangeLlave(input) {
    if (!input.files[0]) {
      this.is_valid_cryptokey = false;
    }
    else if (input.files[0].size < 2000000) {
      let img: any;
      img = document.createElement("imgid");
      img.src = window.URL.createObjectURL(input.files[0]);
      let reader = new FileReader(); // Crear un FileReader
      // Agregar un event listener para el cambio
      reader.addEventListener("load", (event) => {
        img.src = event.target["result"]; // Obtener el (base64 de la imagen)
        (<RegistrationUserBusiness>this.registrationUser).llaveCriptografica = img.src.split(',')[1];
        this.file_src_cedfront = img.src; // Redimensionar la imagen
      }, false);
      reader.readAsDataURL(input.files[0]);
      this.is_valid_cryptokey = true;
      this.touched_files_business = true;
    } else {
      this.is_valid_cryptokey = false;
      console.log("tamaño del archivo: ", input.files[0].size);
      this.inputLlave.nativeElement.value = "";
      this.msgService.pop("error", "Error", "El archivo es demasiado grande");
    }
  }
}
