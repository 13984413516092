import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../service/profile.service';

@Component({
  selector: 'profile-menu',
  templateUrl: 'profile-menu.component.html'
})

export class ProfileMenuComponent implements OnInit {
  public profile: any;
  public currentAccount: any;

  constructor(private profileService: ProfileService) {

  }

  ngOnInit() {
    this.profileService.profile.subscribe((profile) => {
      this.profile = profile;
      this.currentAccount = profile;
    });
  }

  /**
   *  Metodo que genera un hash a partir de un string
   */
  hashCode(str) { // java String#hashCode
    let hash = 0;
    if (str) {
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
    }
    return hash;
  }

  /**
   * genera un color de rbg valido a partir de un int, el int es generado por una funcion de hash
   */
  intToRGB(i) {
    let c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }
}
