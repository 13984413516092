import {Component, OnInit} from '@angular/core';
import {ToasterService} from "angular2-toaster/angular2-toaster";
import {UsuarioSecundarioService} from '../../../service/EVEx-Facturador/usuarioSecundario/usuarioSecundario.service'
import {UsuarioSecundarioRequest} from '../../../model/usuarioSecundario/usuarioSecundarioRequest'
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app_usuario-nuevo',
    templateUrl: './usuario-nuevo.component.html',
    styleUrls: ['./usuario-nuevo.component.css'],
    providers: [ToasterService, UsuarioSecundarioService]
})

export class UsuarioNuevoComponent implements OnInit {
    public Autorizado: boolean = true;
    public loadingData: boolean = false;
    public refreshDate: Date;
    public usuarioSecundario: UsuarioSecundarioRequest;
    public usuarioCreado: boolean = false;
    public idUsuarioPrincipal: string;

    constructor(public usuarioSecundarioService: UsuarioSecundarioService, public toasterService: ToasterService, public route: ActivatedRoute) {
        this.refreshDate = new Date();
    }

    ngOnInit() {
        this.usuarioSecundario = new UsuarioSecundarioRequest();
    }

    registrarUsuarioSecundario() {
        this.loadingData = true;

        this.usuarioSecundarioService.postUsuarioSecundario(this.usuarioSecundario).subscribe(data => {

            this.loadingData = false;
            this.usuarioCreado = true;
            this.toasterService.pop('Success', 'Exito al registrar el usuario', 'El usuario se ha registrado exitosamente');
        }, error => {
            if (error.status === 401) {
                this.Autorizado = false;
            } else if (error.status === 404) {
                //vacío
            } else {
                this.toasterService.pop('error', 'Error al registrar al usuario', 'Hubo un error al registrar al usuario')

            }
            this.loadingData = false;
        });

    }

    limpiarFormulario() {
        this.loadingData = false;
        this.usuarioCreado = false;
        this.Autorizado = true;
    }
}