import {AbstractControl, ValidatorFn} from '@angular/forms';
//^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$
const expressionNumbers = /\d/;
const expressionSpecialCaracter = /[^a-zA-Z\d\s:]/;
const expressionUppercaseLetter = /[A-Z]/;
const expressionLowercaseLetter = /[a-z]/;
const expressionWhiteSpace = /\s/;


export function userPasswordValidator(): ValidatorFn {


    return (control: AbstractControl): { [key: string]: any } => {
        var hasNumbers = false;
        var hasSpecialCaracter = false;
        var hasUppercaseLetter = false;
        var hasLowercaseLetter = false;
        var hasWhitespace = false;

        hasNumbers = expressionNumbers.test(control.value);
        hasSpecialCaracter = expressionSpecialCaracter.test(control.value);
        hasUppercaseLetter = expressionUppercaseLetter.test(control.value);
        hasLowercaseLetter = expressionLowercaseLetter.test(control.value);
        hasWhitespace = expressionWhiteSpace.test(control.value);

        var err = {};
        if (!hasNumbers) {
            err['numeros'] = true;
        }
        if (!hasSpecialCaracter) {
            err['caracterespecial'] = true;
        }
        if (!hasUppercaseLetter) {
            err['mayuscula'] = true;
        }
        if (!hasLowercaseLetter) {
            err['minuscula'] = true;
        }
        if (hasWhitespace) {
            err['espacios'] = true;
        }
        if(hasNumbers && hasSpecialCaracter && hasUppercaseLetter && hasLowercaseLetter && !hasWhitespace){
            return null;
        }
        else{
            return err;
        }
            
        


    };
}
