import {Injectable} from "@angular/core";
import {CognitoCallback, CognitoUtil, LoggedInCallback} from "./cognito.service";
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import {Observable, Observer} from "rxjs/Rx";
import {CognitoStorage} from "./cognito-storage";

/**
 * Flecha Roja Technologies
 * Fernando Aguilar
 * Servicio encargado de administrar la sesión del usuario y la seguridad,
 */

@Injectable()
export class UserLoginService {

  private storage: CognitoStorage = new CognitoStorage();
  /**
   * Invocado cuando hay una respuesta satisfactoria de incio de sesion
   * @param callback invocado cuando
   * @param session la respuesta de Cognito en el onSuccess
   */
  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

    console.log("Autenticado onSuccess, sesión:", session);
    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());
    //en el caso de un success entonces se establecen las cookies para que sean leidas por los demas subdominios
    let username = session.getIdToken()['payload']['cognito:username'];
    this.storage.setItem("CognitoIdentityServiceProvider." + this.cognitoUtil.getPoolData().ClientId + ".LastAuthUser", username);
    this.storage.setItem("CognitoIdentityServiceProvider." + this.cognitoUtil.getPoolData().ClientId + "." + username + ".accessToken", "" + session.getAccessToken().getJwtToken());
    this.storage.setItem("CognitoIdentityServiceProvider." + this.cognitoUtil.getPoolData().ClientId + "." + username + ".refreshToken", "" + session.getRefreshToken().getToken());
    this.storage.setItem("CognitoIdentityServiceProvider." + this.cognitoUtil.getPoolData().ClientId + "." + username + ".idToken", "" + session.getIdToken().getJwtToken());
    this.storage.setItem("CognitoIdentityServiceProvider." + this.cognitoUtil.getPoolData().ClientId + "." + username + ".clockDrift", "" + session['clockDrift']);
    callback.cognitoCallback(null, session);
  };
  private onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback(err.message, null);
  };

  constructor(public cognitoUtil: CognitoUtil) {
  }

  /**
   *  Autentica al usuario, si se recibe una llamada satisfactoria de inicio de sesion, se invoca a la funcion this.onLoginSuccess
   * @param username es el nombre de usuario (o alias)
   * @param password es la contraseña
   * @param callback es la funcion que se llama cuando se obtenga respuesta del servicio de Cognito
   */
  authenticate(username: string, password: string, callback: CognitoCallback) {
    console.log("UserLoginService: starting the authentication");

    let authenticationData = {
      Username: username,
      Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    console.log("UserLoginService: Params set...Authenticating the user");
    let cognitoUser = new CognitoUser(userData);
    console.log("UserLoginService: config is " + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
      onSuccess: result => this.onLoginSuccess(callback, result),
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  /**
   * Inicia el proceso de restablecimiento de la contraseña del usuario
   * @param username nombre de usuario
   * @param callback funcion que sera llamda cuando se reciba respuesta de Cognito
   */
  forgotPassword(username: string, callback: CognitoCallback) {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function () {

      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  /**
   * Confirma el cambio de contrasena del usuario
   * @param email es el usuario o el email del usuario (en caso de ser un alias)
   * @param verificationCode es el codigo de verificacion enviado al correo
   * @param password es la nueva contrasena
   * @param callback es la funcion que se invocara cuando se reciba respuesta de cognito
   */

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    let userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  /**
   * Cierra la sesión del usuario
   */
  logout() {
    console.log("UserLoginService: Logging out");
    this.cognitoUtil.getCurrentUser().signOut();
  }

  /**
   * Determina si un usuario está en sesión.
   * @param callback funcion que sera invocada cuando se reciba respuesta sobre el estado del usuario
   */
  isAuthenticatedCallback(callback: LoggedInCallback) {
    if (callback == null) {
      throw new Error("UserLoginService: Callback in isAuthenticated() cannot be null");
    }

    let cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
          callback.isLoggedIn(err, false);
        }
        else {
          console.log("UserLoginService: Session is " + session.isValid());
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      console.log("UserLoginService: can't retrieve the current user");
      callback.isLoggedIn("Can't retrieve the CurrentUser", false);
    }
  }

  /**
   * Determina si un usuario ha iniciado sesion, retorna un Observable que emitira la respuesta
   * retorna un Observable que emitira el resultado
   */
  isAuthenticated(): Observable<{ error: any, valid: boolean, pool: any }> {
    return new Observable((obs: Observer<{ error: any, valid: boolean, pool: any }>) => {

      let cognitoUser = this.cognitoUtil.getCurrentUser();

      if (cognitoUser != null) {

        cognitoUser.getSession(function (err, session) {
          if (err) {
            console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
            obs.next({error: err, valid: false, pool: null});
            obs.complete();
            return
          }
          else {

            // console.log("UserLoginService: Session is " , session);
            //se obtiene el 'issuer' del token para obtener el user pool id de el
            let iss = session.accessToken.payload.iss;
            obs.next({
              error: err,
              valid: session.isValid(),
              pool: iss.split("/")[iss.split("/").length - 1]
            });
            obs.complete();
            return
          }
        });
      } else {
        console.log("UserLoginService: can't retrieve the current user");
        obs.next({error: "Can't retrieve the CurrentUser", valid: false, pool: null});
        obs.complete();
        return
      }
    });
  }

  /**
   * Determina si un usuario ha iniciado sesion, retorna un Observable que emitira la respuesta
   * retorna un Observable que emitira el resultado
   * La diferencia es que con este metodo se puede espeicificar con cual configuracion se validara si esta autenticado
   */
  isAuthenticatedWithConfig(userPoolId: string, clientId: string): Observable<{ error: any, valid: boolean, pool: any }> {
    return new Observable((obs: Observer<{ error: any, valid: boolean, pool: any }>) => {
      this.cognitoUtil.setConfig(userPoolId, clientId);
      let cognitoUser = this.cognitoUtil.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
            obs.error({error: err, valid: false, pool: null});
            obs.complete();
            return
          }
          else {
            // console.log("UserLoginService: Session is " , session);
            //se obtiene el 'issuer' del token para obtener el user pool id de el
            let iss = session.accessToken.payload.iss;
            obs.next({
              error: err,
              valid: session.isValid(),
              pool: iss.split("/")[iss.split("/").length - 1]
            });
            obs.complete();
            return
          }
        });
      } else {
        console.log("UserLoginService: can't retrieve the current user");
        obs.error({error: "Can't retrieve the CurrentUser", valid: false, pool: null});
        obs.complete();
        return
      }

    });
  }

  /**
   * Cambia la configuracion del userpool y client id con los cuales se conecta a cognito
   * @param userPoolId
   * @param clientId
   */
  setConfig(userPoolId, clientId) {
    this.cognitoUtil.setConfig(userPoolId, clientId);
  }


}
